import { createReducer, on } from '@ngrx/store';
import * as UserActionTypes from '../actions/user.action';
import { Permissions } from '../../domain/permissions.model';
import { Role } from '../../domain/role.model';
import { DirectoryUser } from '../../domain/directory-user.model';

export interface UserState {
  roles: Role[];
  permissions: Permissions[];
  directoryUsers: DirectoryUser[];
}

export const initialUserState: UserState = {
  roles: [],
  permissions: [],
  directoryUsers: null,
};

export const userReducer = createReducer(
  initialUserState,
  on(
    UserActionTypes.fetchUserRolesSuccess,
    (state: UserState, { roles }): UserState => ({
      ...state,
      roles: roles,
    }),
  ),
  on(
    UserActionTypes.directoryUsersFetched,
    (state: UserState, { users }): UserState => ({
      ...state,
      directoryUsers: users,
    }),
  ),
  on(
    UserActionTypes.fetchPermissionSuccess,
    (state: UserState, { permissions }): UserState => ({
      ...state,
      permissions: permissions,
    }),
  ),
  on(UserActionTypes.resetAllUserStore, () => ({
    ...initialUserState,
  })),
);

export const getUserRoles = (state: UserState): Role[] => state.roles;
export const getAllPermissions = (state: UserState): Permissions[] => state.permissions;
