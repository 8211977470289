import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { Subscription } from 'rxjs';
import { Constants } from '../../../../../core/utils/app.constants';
import { FieldValidationResult } from '../../../../../modules/master/services/master-validation';
import { TimeService } from '../../../../../core/ngrx/services/time-service';
import { isNullOrUndefined } from '../../../../../modules/utils/object-utils';
import { extractEvent } from '../../../../../modules/utils/extract-event';

const quickRegInternational = /^(([0-2]\d)|[4-9]|30|31)((0\d)|[2-9]|10|11|12)((1[3-9])|([2-9]\d))$/; // ddmmyy (>2012)
const quickRegMonth = /^((0\d)|[2-9]|10|11|12)((1[3-9])|([2-9]\d))$/; // mmyy (indep. of lang; >2012)

export type MonthCompletion = 'none' | 'first' | 'last';

@Component({
  selector: 'ncs-date-input',
  templateUrl: 'ncs-date-input.component.html',
})
export class NcsDateInputComponent implements OnDestroy, OnChanges {
  @Input() readOnly: boolean = false;
  @Input() value: Date;
  @Input() showIcon: boolean = true;
  @Input() inline: boolean = false;
  @Input() monthCompletion: MonthCompletion = 'none';
  @Input() minDate: Date;
  @Input() disabled: boolean;
  @Input() focusable: boolean = true;
  @Input() tabIndex: number;
  @Input() maxDate: Date;
  @Input() validationResult: FieldValidationResult;
  @Input() inputStyleClass: string = '';
  @Input() editable: boolean = true;
  @Input() formatType: string = 'date';
  @Input() toTimezone: boolean = true;
  /** @see More Formats: https://primefaces.org/primeng/showcase/#/calendar
   * WARN: For date pipe mm -> minutes and MM -> Month two digits
   * */
  @Input() dateFormat: string = Constants.getDateFormatShort();
  @Input() dateFormatDisplay: string = Constants.getDateFormat();
  @Input() showTime: boolean = false;
  @Input() touchUI: boolean = false;
  @Input() disabledInput: boolean;
  @Output() onblur: EventEmitter<any> = new EventEmitter();

  @ViewChild(Calendar) calRef: Calendar;
  @ViewChild(Calendar, { read: ElementRef }) calDomRef: ElementRef;
  @Output() valueChange: EventEmitter<Date> = new EventEmitter();

  private registeredInput: HTMLElement;
  private readonly translateServiceToken: Subscription;
  protected readonly Constants = Constants;
  public primeNgDateValue: any;
  public hasGotFocus: boolean = false;

  constructor(private readonly timeService: TimeService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const change: SimpleChange = changes.value;
      const dateValue: Date = change.currentValue;
      this.primeNgDateValue = null;
      if (!isNullOrUndefined(dateValue)) {
        // this.primeNgDateValue = date;
        if (this.toTimezone) {
          this.primeNgDateValue = this.timeService.toTimezone(dateValue);
        } else {
          // - convert to date in browser timezone using Javascript Date // thx to PrimeNG
          this.primeNgDateValue = this.timeService.toJavaScriptDateIgnoringTimezone(dateValue);
        }
      }
    }
  }

  onFocus(): void {
    // (only on non-inline)
    this.hasGotFocus = true;
    // add handlers to input - not pretty but functional
    const input = this.calDomRef.nativeElement.getElementsByTagName('input')[0];
    if (this.registeredInput === input) {
      return;
    }
    this.registeredInput = input;
    input.addEventListener('input', event => this.onInput(event)); // preserve 'this' below ;-)
  }

  onInput(event, completeMonth?: boolean): void {
    const val = event.target.value;
    let dayPart: number;
    let monthPart: number;
    let yearPart: number;
    let dateValue: Date;
    let doSet = false;
    const regRes = quickRegInternational.exec(val);
    const regResMonth = quickRegMonth.exec(val);
    if (regRes) {
      dayPart = parseInt(regRes[1], 10);
      monthPart = parseInt(regRes[3], 10);
      yearPart = parseInt(regRes[5], 10);
      if (yearPart < 100) yearPart += 2000;
      dateValue = this.timeService.createDate(yearPart, monthPart, dayPart);
      doSet = true;
      // for datePeriod
    } else if (completeMonth && (this.monthCompletion === 'first' || this.monthCompletion === 'last') && regResMonth) {
      monthPart = parseInt(regResMonth[1], 10);
      yearPart = parseInt(regResMonth[3], 10);
      if (yearPart < 100) yearPart += 2000;
      dateValue =
        this.monthCompletion === 'first'
          ? this.timeService.createStartOfMonthDate(yearPart, monthPart)
          : this.timeService.createEndOfMonthDate(yearPart, monthPart);
      doSet = true;
    }
    if (doSet && dateValue) {
      this.valueChange.emit(dateValue);
    }
  }

  onModelChange(event: any): void {
    // WARN: Conflict when enable clock setting. Only uses in grid date filter
    // value changed by PrimeNG - convert to date in correct timezone using timeservice
    const date: Date = extractEvent(event);
    // if(date) date = this.timeService.createDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    // if(isNullOrUndefined(date) && isNullOrUndefined(this.primeNgDateValue)) return; // do not propagate, value has not changed
    this.valueChange.emit(date);
  }

  /**
   * this is called when a date is selected via picker. We have to call blur from here because:
   * 1. the calendar already lost focus after opening the picker (emitted onBlur)
   * 2. model changed after selection (happens later, emitted valueChange)
   * 3. other components re-validate input and insert defaults to other fields based on onBlur (which would not happen with the updated value without this...)
   */
  onSelect(): void {
    this.onChangeDate();
  }

  onBlur(event: any): void {
    this.hasGotFocus = false;
    this.onInput(event, true);
    this.onblur.emit(event);
  }

  ngOnDestroy(): void {
    if (this.translateServiceToken) this.translateServiceToken.unsubscribe();
  }

  public onClickToday(): void {
    this.valueChange.emit(new Date());
  }

  public onChangeDate(): void {
    this.valueChange.emit(this.primeNgDateValue);
  }
}
