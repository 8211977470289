import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import * as log from 'loglevel';
import {
  AUTHORITY_URL,
  LocalEnvironment,
  MsalEnvironment,
  ProductionEnvironment,
  TENANT_ID,
  TestEnvironment,
  UatEnvironment,
} from './msal-environment';
import { isNullOrUndefined } from '../../../utils/object-utils';
import { environment } from '../../../../../environments/environment';

const allEnvironments = [
  new LocalEnvironment(),
  new ProductionEnvironment(),
  new TestEnvironment(),
  new UatEnvironment(),
];

export abstract class MsalEnvironments {
  static environment(): MsalEnvironment {
    let activeEnv: MsalEnvironment;
    const { origin } = window;
    activeEnv = allEnvironments.find(x => x.chooseThis(origin));
    activeEnv = !isNullOrUndefined(activeEnv) ? activeEnv : new LocalEnvironment();
    if (isNullOrUndefined(activeEnv)) {
      throw new Error('None msal environment could be found');
    }
    return activeEnv;
  }
}

const env: MsalEnvironment = MsalEnvironments.environment();

const isIE: boolean =
  window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
  if (!environment.production) {
    log.setLevel(logLevel);
    log.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: msalClientConfig.msalConfig.auth.clientId,
      redirectUri: window.location.origin,
      authority: msalClientConfig.msalConfig.auth.authority,
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // Define which permissions (=scopes) we need it for Microsoft Graph
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', msalClientConfig.apiConfig.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: msalClientConfig.apiConfig.scopes,
      redirectUri: window.location.origin,
    },
    loginFailedRoute: window.location.origin,
  };
}

export const msalClientConfig = {
  msalConfig: {
    auth: {
      clientId: env.getClientId(),
      authority: AUTHORITY_URL + TENANT_ID,
    },
  },
  apiConfig: {
    scopes: ['openid', 'user.read'],
    redirectUri: env.getRedirectUrl(),
  },
};
