<div class="grid col-12">
  <div class="col-12">
    <ncs-checkbox-basic
      [disabled]="!editMode"
      [title]="'common.labels.use_all_cup_evaluators'"
      [value]="currentItem?.useAllCupEvaluators"
      [label]="'common.labels.use_all_cup_evaluators'"
      (onChange)="onChangeUseCupEvaluators($event)">
    </ncs-checkbox-basic>
  </div>
  <div class="col-12 xl:col-6 lg:col-6 md:col-7 sm:col-10">
    <ncs-generic-search
      [label]="'common.labels.cup_evaluators_by_tenant'"
      [titleGrid]="'common.labels.cup_evaluators_by_tenant'"
      [typeEntitySearch]="cupEvaluatorEntity"
      [value]="selectedCupEvaluators"
      [editable]="editMode"
      [btnMagnifier]="true"
      [multiple]="true"
      [serviceUrl]="onGetUrlService()"
      [paginationRequestParams]="cupEvaluatorRequestParams"
      [fieldDisplay]="'cupEvaluator'"
      [validationResult]="validationResult?.fieldResults?.cuppingSessionCupEvaluators"
      (onUnSelected)="onRemoveCupEvaluator($event)"
      (onchange)="onSelectCupEvaluator($event)"
      (onblur)="onItemFieldBlur('cuppingSessionCupEvaluators')"
      (onSelected)="onSelectCupEvaluator($event)">
    </ncs-generic-search>
  </div>
  <div class="col-12 xl:col-2 lg:col-2 md:col-4 sm:col-2">
    <div class="col-12">
      <p-button
        *ngIf="editMode"
        icon="pi pi-plus"
        (click)="showGuestFormDialog = true"
        pTooltip="{{ 'dialog.header.add_guest_evaluator' | translate }}"></p-button>
    </div>
  </div>
</div>
<div class="col-12">
  <nkg-generic-grid
    [id]="'CupEvaluator'"
    [wrappedInCard]="false"
    [agGridStyle]="{ width: '100%', height: '250px' }"
    [rowData]="cuppingSessionCupEvaluatorRowData"
    [colDef]="columnDefsCupEvaluators"
    [gridOptions]="gridCuppingSessionEvaluators"
    [rowId]="'CupEvaluator'">
  </nkg-generic-grid>
</div>

<ncs-dialog-basic
  [headerText]="'dialog.header.add_guest_evaluator'"
  [visible]="showGuestFormDialog"
  [showOnModal]="true"
  [primaryButtonText]="'common.buttons.add'"
  [secondaryButtonText]="'common.buttons.close'"
  class="upload-gallery-cup-image"
  (onSecondaryClicked)="onCancelNewGuestEvaluator()"
  (onPrimaryClicked)="onGuestFormConfirmed()">
  <guest-evaluator-form [ngStyle]="{ 'z-index': '9999' }"></guest-evaluator-form>
</ncs-dialog-basic>
