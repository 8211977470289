import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PrimeIcons } from 'primeng/api';
import { delay } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { fetchSearchGenericItems, setSearchGenericItems } from '../../../core/ngrx/actions';
import * as fromRoot from '../../../core/ngrx/reducers';
import { isNullOrUndefined, mergeImmutable, nullsafe } from '../../../modules/utils/object-utils';
import { isNotEmpty, replaceStringForRegexp } from '../../../modules/utils/string-utils';
import { NcsBaseBasicComponent } from '../../basic-shared-module/components/base-basic/base-basic.component';
import { PaginationRequestParams } from '../../../core/domain/pagination-request-params.model';
import { PaginationMetadata } from '../../../core/domain/pagination-metadata.model';
import { isArrayEmpty } from '../../../modules/utils/array-utils';
import { PaginatedResponse } from '../../../core/domain/paginated-response.model';
import { LookupParams } from '../../models/lookup-params.model';
import { ColDefMap } from '../../models/col-def-map.model';
import { ColumnsType } from '../../models/columns-type.model';
import { GridSelectionMode } from '../../models/grid-selection-mode.model';

@Component({
  selector: 'ncs-generic-search',
  templateUrl: './search-generic.component.html',
})
export class NcsGenericSearch extends NcsBaseBasicComponent implements OnInit, OnChanges, OnDestroy {
  // Emit new item selections
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Output() onUnSelected: EventEmitter<any> = new EventEmitter();
  @Output() onSelectedRows: EventEmitter<any> = new EventEmitter(); // getSelections rows of grid

  /** Activate the button to display the results in the grid. */
  @Input() btnMagnifier: boolean = false;
  @Input() focusable: boolean = true;
  /** Value used and shared by lookup and grid search */
  @Input() targetValue: string = '';
  /** Field for display values into p-autocomplete */
  @Input() fieldDisplay: string;
  /** Selection mode: multiple or single */
  @Input() multiple: boolean = false;
  /** Entity type for generic search to backend app */
  @Input() typeEntitySearch: Function;
  /** Custom Url service for searches on backend */
  @Input() serviceUrl: string = null;
  /** Remove button search icon and to replace for this label */
  @Input() searchNameBtn: string;
  /** Disables input search and activates only the grid detail search */
  @Input() onlyGrid: boolean = false;
  /** Enable searches by grid columns */
  @Input() filterBySplit: boolean;
  @Input() genericSearchGridColumnType: ColumnsType = ColumnsType.COL_DEF;
  /** Setting title property of grid */
  @Input() titleGrid: string;
  /** Use when needs nested functions or complex logic into column definitions
   * Otherwise, use paginationRequestParams
   * */
  @Input() colDefinition: ColDefMap[];
  @Input() placeholder: string = 'grid.labels.search_and_select';
  /** Params for services of search to backend */
  @Input() paginationRequestParams: PaginationRequestParams = new PaginationRequestParams();
  /** PrimeNg icon
   * @default {@link PrimeIcons.SEARCH}
   */
  @Input() btnIcon = PrimeIcons.SEARCH;
  // properties for local logic
  public pagination: PaginationMetadata;
  public displayGrid: boolean = false;
  public suggestions: any[];
  public gridSelectionMode = GridSelectionMode;
  public colDefArray: string[];
  public $filteredSuggestions: BehaviorSubject<any[]> = new BehaviorSubject([]);

  private query: string; // word typed and search matching on the server with any entity or property
  private lastTargetValue: string = ''; // Used for recovery last query found on lookup or grid feature.
  private wasSelected: boolean = false;
  private readonly genericSearchSubscription$: Subscription;

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly store: Store<fromRoot.State>,
  ) {
    super();
    this.genericSearchSubscription$ = this.storeToProps();
  }

  // start when component is init for first time and when value is changed
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.$filteredSuggestions.next([]);
      // WARN: Force detection changes on multi-option
      if (this.multiple) setTimeout(() => this.cd.markForCheck());
    }
  }

  ngOnInit(): void {
    // For generic search, this component use NgRx storage
    // The generic search need column definition now
    if (isArrayEmpty(this.colDefinition) && !isNullOrUndefined(this.paginationRequestParams)) {
      // get generic column definition from defined params
      this.colDefArray = this.onSetToLabelToSnakeCase();
    }
  }

  ngOnDestroy(): void {
    this.$filteredSuggestions.next([]);
    this.$filteredSuggestions.complete();
    this.genericSearchSubscription$.unsubscribe();
  }

  /** Fetch new searches
   * @param {LookupParams} queryParams */
  public onSearch(queryParams: LookupParams): void {
    if (!isNullOrUndefined(queryParams)) {
      this.query = queryParams.query;
      this.targetValue = this.query;
      this.paginationRequestParams = mergeImmutable(this.paginationRequestParams, {
        size: 10,
        q: queryParams.query,
        page: queryParams.page,
        split: this.filterBySplit,
      });
      this.store.dispatch(
        fetchSearchGenericItems({
          typeFunction: this.typeEntitySearch,
          paginationRequestParams: this.paginationRequestParams,
          serviceUrl: this.serviceUrl,
        }),
      );
    }
  }

  /**
   * Emit new item selected.
   * Ever retrieve current single selection
   * @param newSelection new selected item
   */
  public onSelectionEvent(newSelection: any): void {
    this.wasSelected = true;
    this.value = newSelection;
    // this event is emitted when any item is selected.
    // WARN: selected validation work for single select and not work for multiple selections
    this.onSelected.emit(newSelection);
  }

  // for multi and single selection features, the validation it's complicated
  // so, correct validations is do it through wasSelected variable
  // CAUTION: validate onSelected output event on component root emitter
  public onBlurEvent(event: any): void {
    // CAUTION: event target work only for single selection
    if (!this.multiple && this.wasSelected) {
      const changeValue = event.target?.value;
      if (!isNullOrUndefined(changeValue)) {
        // Validation for current selection
        if (!isEqual(changeValue, this.value[this.fieldDisplay])) {
          // if the current model value doesn't match with current changes, then set value to undefined
          this.onSelected.emit(undefined);
          this.wasSelected = false;
          event.target.value = ''; // clear invalid selection
        }
      } else {
        // kill existing selection for empty
        this.onSelected.emit(undefined);
      }
      this.wasSelected = false;
    }
    this.onBlur(event);
  }

  /** Similar onSelect event.
   * This only multi selection mode and fire up before that onSelection event
   * @param event - primeNg model Event
   * @return Output changes emitting
   */
  public onChangeNgModel(event: any): void {
    // Force store changes when suggestion is the same from server results.
    // When server retrieves the same result, the ngrx-store no emmit changes and not display suggestions for user
    this.store.dispatch(setSearchGenericItems({ paginationResponse: null }));
    this.onChanges(event);
  }

  /** Emit unSelection item (multi-selection only)
   * @param event - same type for model */
  public onUnSelection(event: any): void {
    this.onUnSelected.emit(event);
  }

  /** Formatting data displayed
   * @return {string} - Data formatted */
  public customResult(obj: any): string {
    let str = obj[this.fieldDisplay]; // no property is a string array
    if (!this.query || isNullOrUndefined(str)) {
      return str;
    }
    const reg = new RegExp(`(|\\W)(${replaceStringForRegexp(this.query)})`, 'gi');
    str = str.replace(reg, '$1<b>$2</b>');
    // apply a format as SelectItem
    str = this.formatDisplay(str);
    return str;
  }

  /** Clean customizable format
   * @return {string} string cleaning */
  public formatDisplay(str: string): string {
    // CAUTION: the input component will always remove some characters
    // as line breaks from the selected (display) value.
    // So we better remove these characters. Otherwise,
    // the selected value could be different
    // from the internal selection.
    const reg = /\r?\n/g;
    str = str.replace(reg, ' ');
    return str;
  }

  /**
   * Emit the page changes on grid
   * @param event {number}
   */
  public changePageGrid(event: number): void {
    this.lastTargetValue = isNotEmpty(this.targetValue) ? this.targetValue : this.lastTargetValue;
    this.onSearch({ query: this.lastTargetValue, page: event });
  }

  /** Get result lookup's grid and setting internal selection value
   * @param newSelectedItems Entity type of selection result
   */
  public getResultSelections(newSelectedItems: any): void {
    this.onSelected.emit(newSelectedItems);
    this.wasSelected = true;
    if (!this.multiple) {
      // Manual validation for single item selected from agGrid
      this.value = newSelectedItems;
      this.onBlurEvent({ target: { value: this.value[this.fieldDisplay] } }); // force valid selection when any row is selected
    }
  }

  /** Setting suggestions when makes new searches */
  private storeToProps(): Subscription {
    return this.store
      .select(fromRoot.getGenericSearchItems)
      .pipe(delay(300))
      .subscribe(genericResponse => {
        this.$filteredSuggestions.next([...this.onSetSuggestions(genericResponse)]);
      });
  }

  // stop keyboard ESCAPE event everywhere
  // into masters-data, keyboard escape event cause exit edit mode
  @HostListener('document:keydown', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' && this.displayGrid) {
      event.stopPropagation();
    }
  }

  /**
   * Set column definitions from column property of the {@link PaginationRequestParams}
   * @private
   */
  private onSetToLabelToSnakeCase(): string[] {
    return this.onGetColumns(this.paginationRequestParams.columns).map((item: string) =>
      item.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase(),
    );
  }

  /**
   * Split all columns defined in {@link PaginationRequestParams}
   * @param {string} varToSplit column names for split
   * @private
   */
  private splitColumns(varToSplit: string): string[] {
    if (varToSplit.trim().length) return varToSplit.split(',');
    return [];
  }

  /**
   * Get all columns and split.
   * @param {string} stringToSplit Column names
   * @Return An array of the column names.
   * @private
   */
  private onGetColumns(stringToSplit: string): string[] {
    const mySplit = this.splitColumns(stringToSplit);
    return nullsafe(mySplit).map(item => item.split('.')[0]);
  }

  /** Click event from magnifier button */
  public onClickMagnifierBtn(): void {
    this.$filteredSuggestions.next([]);
    this.suggestions = [];
    this.displayGrid = true;
  }

  private onSetSuggestions(response: PaginatedResponse): any[] {
    this.pagination = response?.__pagination;
    // Is used for grid's filtered data
    this.suggestions = nullsafe(response?.items);
    return this.suggestions;
  }
}
