import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractEntity } from './abstract-entity.model';
import { Tenant } from './tenant.model';
import { Agency } from './agency.model';
import { Role } from './role.model';
import { Language } from './language.model';
import { Permission } from './permission.model';
import { ProtectionDomain } from './protection-domain.model';
import { nullsafe } from '../../modules/utils/object-utils';
import { permissionTable } from './protection-table.model';

@Entity('authentication', 'NcsUser', 'auth')
export class User extends AbstractEntity {
  @Serialized username?: string;
  @Serialized password?: string;
  @Serialized displayName?: string;
  @Serialized firstName?: string;
  @Serialized lastName?: string;
  @Serialized phoneNumber?: string;
  @Serialized email?: string;
  @Serialized jobTitle?: string;
  @Serialized department?: string;
  @Internal dn?: string;
  @Serialized admin?: boolean;
  @Serialized({ elementType: String }) permissions?: Permission[] = [];
  @Internal tenant?: Tenant;
  @Serialized defaultTenant: Tenant;
  @Serialized defaultAgency: Agency;
  @Serialized({ elementType: Tenant }) tenants?: Tenant[] = [];
  @Serialized({ elementType: Agency }) agencies?: Agency[] = [];
  @Serialized({ elementType: Role }) roles?: Role[] = [];
  @Serialized dashboard: string;
  @Serialized language: Language;
  @Serialized deactivated?: boolean;
  @Internal cupEvaluatorId?: number; // used when any cupping is created and needs set its cup evaluator id

  hasPermissions(permissions: Permission[]): boolean {
    if (nullsafe(permissions).length === 0) return true;
    return permissions.some(allowing => !!this.permissions.find(p => p === allowing));
  }

  canAccessProtectedDomain(domain: ProtectionDomain): boolean {
    const permissions: Permission[] = permissionTable.get(domain);
    return this.hasPermissions(permissions);
  }
}
