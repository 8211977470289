import { TranslateService } from '@ngx-translate/core';
import { Message } from 'primeng/api';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

export function setToastNotification(param: ToastNotification): Message {
  return {
    key: 'app-notification-toast',
    severity: param.severity,
    detail: getMessageParam(param?.detailWithParameters || param?.detail, param.translateService),
    summary: getMessageParam(param?.summaryWithParameters || param.summary, param.translateService),
    sticky: param.sticky,
    life: param.life,
  };
}

export function getMessageParam(detail: any, translateService: TranslateService): any {
  const message = detail?.message || detail;
  const param = detail?.parameter || detail;
  if (!isNullOrUndefined(detail)) {
    return translateService.instant(message, param);
  }
  return null;
}

export interface ToastNotification extends Message {
  translateService?: TranslateService;
  detailWithParameters?: any;
  summaryWithParameters?: any;
}

export enum ToastSeverityEnum {
  SUCCESS = 'success',
  WARNING = 'warn',
  INFO = 'info',
  ERROR = 'error',
}

export type NotificationHandler = {
  [P in keyof MessageParams]: MessageParams[P];
};

export interface MessageParams extends Message {
  detailWithParameters?: { message: string; parameter: Object };
  summaryWithParameters?: { message: string; parameter: Object };
}
