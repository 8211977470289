import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Column } from 'ag-grid-community';
import { ColumnState } from 'ag-grid-community/dist/lib/columnController/columnController';
import { isNullOrUndefined, nullsafe } from '../../../../modules/utils/object-utils';
import { ColumnVisibility, DefaultColumnVisibility } from '../../models/enums';

@Component({
  selector: 'ncs-customize-table-overlay',
  templateUrl: './customize-table-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizeTableOverlayComponent implements OnChanges {
  @Input() allColumns: Column[]; // from gridOptions
  @Input() columnStates: ColumnState[]; // from gridOptions[ColumnApi]
  @Input() defaultVisibility: DefaultColumnVisibility;
  @Input() visible: boolean = false;

  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() apply: EventEmitter<ColumnVisibility[]> = new EventEmitter();

  visibleFinally: boolean = false;

  currentColVisibility: ColumnVisibility[] = [];
  availableColumns: ColumnVisibility[] = [];
  isColumnChanged = false;

  constructor(
    @Inject('wndw') private readonly wndw: Window,
    private readonly cd: ChangeDetectorRef,
  ) {}

  // WARNING: use changeDetectionStrategy.OnPush on a root component for not bucle
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      if (changes.visible.currentValue) {
        // we become true
        this.visibleFinally = false; // yes, indeed - b/c we need to layout first, so the next cycle can center us
        this.wndw.setTimeout(() => {
          this.visibleFinally = true;
          this.cd.markForCheck();
        });
      } else {
        this.visibleFinally = false; // can hide immediately
      }
    }
    if (changes.columnStates)
      if (!this.visibleFinally && nullsafe(changes.columnStates.currentValue).length > 0) {
        const currentCols = this.onGetAvailableColumns(this.allColumns, true);
        this.currentColVisibility = this.onGetOrderedColumns(currentCols);
        this.availableColumns = this.onGetAvailableColumns(this.allColumns, false);
      }
  }

  private onGetAvailableColumns(sourceColumn: Column[], visible: boolean): ColumnVisibility[] {
    return nullsafe(sourceColumn)
      .filter(c => {
        return !c.isPinned() && c.isVisible() == visible;
      })
      .map(cf => ({ column: cf, visible: false }));
  }

  onGetOrderedColumns(cols: ColumnVisibility[]): ColumnVisibility[] {
    // NOTE: The length of ColumnVisibility is less than ColumnState[] because the first one contains only visible columns
    //  and the other one is all defined columns
    return nullsafe(this.columnStates)
      .filter(item => !item.pinned && !item.hide)
      .map(colItem => nullsafe(cols).find((item: ColumnVisibility) => item.column.getColId() === colItem.colId));
  }

  public onSetAsDefaultView(): void {
    const allColumns = nullsafe(this.allColumns)
      .filter(c => !c.getColDef().pinned)
      .map(c => ({ column: c, visible: false }));
    const noDefaultColumns: ColumnVisibility[] = [];
    this.currentColVisibility = allColumns.filter(cv => {
      if (isNullOrUndefined(this.defaultVisibility[cv.column.getColId()])) noDefaultColumns.push(cv);
      return !isNullOrUndefined(this.defaultVisibility[cv.column.getColId()]);
    });
    this.availableColumns = noDefaultColumns;
    this.isColumnChanged = true;
  }

  public onApplyChanges(): void {
    if (this.isColumnChanged && nullsafe(this.currentColVisibility).length > 0) {
      const newVisibilityColumns = [];
      this.currentColVisibility.forEach(col => {
        // set visible property as true for comparison
        col.visible = true;
        newVisibilityColumns.push(col);
      });
      this.apply.emit(newVisibilityColumns.concat(this.onGetPinnedColumns()));
      this.isColumnChanged = false;
    }
  }

  /**
   * Retrieve pinned columns and means that are locked or disabled to hide visibility
   * @private
   */
  private onGetPinnedColumns(): ColumnVisibility[] {
    return this.allColumns.filter(c => c.isPinned()).map(cf => ({ visible: true, column: cf }));
  }

  public onCancel(): void {
    this.isColumnChanged = false;
    this.cancel.emit();
  }

  isColumnValid = (column: ColumnVisibility): ColumnVisibility => column;

  public setModified(isModified: boolean): void {
    this.isColumnChanged = isModified;
  }
}
