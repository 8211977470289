import { ProtectionDomain } from './protection-domain.model';
import { Permission } from './permission.model';

export const permissionTable: Map<ProtectionDomain, Permission[]> = new Map<ProtectionDomain, Permission[]>()
  .set(ProtectionDomain.ADMIN_SCREEN, [
    Permission.VIEW_USER,
    Permission.EDIT_USER,
    Permission.VIEW_ROLE,
    Permission.EDIT_ROLE,
  ])
  .set(ProtectionDomain.MASTER_DATA_SCREEN, [Permission.COFFEE_TASTING])
  .set(ProtectionDomain.METRIC_DEFINITION_SCREEN, [Permission.COFFEE_TASTING])
  .set(ProtectionDomain.ROLE_SCREEN, [Permission.VIEW_ROLE, Permission.EDIT_ROLE, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.TENANT_SCREEN, [Permission.VIEW_TENANT, Permission.EDIT_TENANT, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.AGENCY_SCREEN, [Permission.VIEW_AGENCY, Permission.EDIT_AGENCY, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.WAREHOUSE_SCREEN, [
    Permission.VIEW_WAREHOUSE,
    Permission.EDIT_WAREHOUSE,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.STAGE_PROCESSING_SCREEN, [
    Permission.VIEW_STAGE_PROCESSING,
    Permission.EDIT_STAGE_PROCESSING,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.MILLING_PROCESS_SCREEN, [
    Permission.VIEW_MILLING_PROCESS,
    Permission.EDIT_MILLING_PROCESS,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.DRYING_PROCESS_SCREEN, [
    Permission.VIEW_DRYING_PROCESS,
    Permission.VIEW_DRYING_PROCESS,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.CUP_EVALUATOR_SCREEN, [
    Permission.VIEW_CUP_EVALUATOR,
    Permission.EDIT_CUP_EVALUATOR,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.COFFEE_PROGRAM_SCREEN, [
    Permission.VIEW_COFFEE_PROGRAM,
    Permission.EDIT_COFFEE_PROGRAM,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.SAMPLE_TYPE_SCREEN, [
    Permission.VIEW_SAMPLE_TYPE,
    Permission.EDIT_SAMPLE_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.USER_SCREEN, [Permission.VIEW_USER, Permission.EDIT_USER, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.LANGUAGE_SCREEN, [Permission.VIEW_LANGUAGE, Permission.EDIT_LANGUAGE, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.COUNTRY_SCREEN, [Permission.VIEW_COUNTRY, Permission.EDIT_COUNTRY, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.REGION_SCREEN, [Permission.VIEW_REGION, Permission.EDIT_REGION, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.STATE_SCREEN, [Permission.VIEW_STATE, Permission.EDIT_STATE, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.COFFEE_SPECIE_SCREEN, [
    Permission.VIEW_COFFEE_SPECIE,
    Permission.EDIT_COFFEE_SPECIE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.COFFEE_VARIETY_SCREEN, [
    Permission.VIEW_COFFEE_VARIETY,
    Permission.EDIT_COFFEE_VARIETY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.THIRD_PARTY_SCREEN, [
    Permission.VIEW_THIRD_PARTY,
    Permission.EDIT_THIRD_PARTY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.THIRD_PARTY_TYPE_SCREEN, [
    Permission.VIEW_THIRD_PARTY_TYPE,
    Permission.EDIT_THIRD_PARTY_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.USER_SCREEN, [Permission.VIEW_USER, Permission.EDIT_USER, Permission.AUTH_SERVICE])
  .set(ProtectionDomain.HARVEST_METHOD_SCREEN, [
    Permission.VIEW_HARVEST_METHOD,
    Permission.EDIT_HARVEST_METHOD,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SOIL_TYPE_SCREEN, [
    Permission.VIEW_SOIL_TYPE,
    Permission.EDIT_SOIL_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_STATUS_SCREEN, [
    Permission.VIEW_SAMPLE_STATUS,
    Permission.EDIT_SAMPLE_STATUS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CONTACT_TYPE_SCREEN, [
    Permission.VIEW_CONTACT_TYPE,
    Permission.EDIT_CONTACT_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.COURIER_COMPANY_SCREEN, [
    Permission.VIEW_COURIER_COMPANY,
    Permission.EDIT_COURIER_COMPANY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_GROUP_SCREEN, [
    Permission.VIEW_METRIC_GROUP,
    Permission.EDIT_METRIC_GROUP,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_TYPE_RESULTS_SCREEN, [
    Permission.VIEW_METRIC_TYPE_RESULTS,
    Permission.EDIT_METRIC_TYPE_RESULTS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_TYPE_SCREEN, [
    Permission.VIEW_METRIC_TYPE,
    Permission.EDIT_METRIC_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.METRIC_DEFINITION_SCREEN, [
    Permission.VIEW_METRIC_DEFINITION,
    Permission.EDIT_METRIC_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.STANDARD_DEFINITION_SCREEN, [
    Permission.VIEW_STANDARD_DEFINITION,
    Permission.EDIT_STANDARD_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.QUALITY_SCREEN, [Permission.VIEW_QUALITY, Permission.EDIT_QUALITY, Permission.COFFEE_TASTING])
  .set(ProtectionDomain.FACT_SHEET_SCREEN, [
    Permission.VIEW_FACT_SHEET,
    Permission.EDIT_FACT_SHEET,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.QUALITY_TYPE_SCREEN, [
    Permission.VIEW_QUALITY_TYPE,
    Permission.EDIT_QUALITY_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.ITEM_DEFINITION_SCREEN, [
    Permission.VIEW_ITEM_DEFINITION,
    Permission.EDIT_ITEM_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CHECK_LIST_DEFINITION_SCREEN, [
    Permission.VIEW_CHECK_LIST_DEFINITION,
    Permission.EDIT_CHECK_LIST_DEFINITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.ADDRESS_TYPE_SCREEN, [
    Permission.VIEW_ADDRESS_TYPE,
    Permission.EDIT_ADDRESS_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_SCREEN, [Permission.VIEW_SAMPLE, Permission.EDIT_SAMPLE, Permission.COFFEE_TASTING])
  .set(ProtectionDomain.PACKAGE_TYPE_SCREEN, [
    Permission.VIEW_PACKAGE_TYPE,
    Permission.EDIT_PACKAGE_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.COFFEE_CERTIFICATION_SCREEN, [
    Permission.VIEW_COFFEE_CERTIFICATION,
    Permission.EDIT_COFFEE_CERTIFICATION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.LABORATORY_SCREEN, [
    Permission.VIEW_LABORATORY,
    Permission.EDIT_LABORATORY,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_SESSION_SCREEN, [
    Permission.VIEW_CUPPING_SESSION,
    Permission.EDIT_CUPPING_SESSION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_SESSION_STATUS_SCREEN, [
    Permission.VIEW_CUPPING_SESSION_STATUS,
    Permission.EDIT_CUPPING_SESSION_STATUS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_DISPATCH_STATUS_SCREEN, [
    Permission.VIEW_SAMPLE_DISPATCH_STATUS,
    Permission.EDIT_SAMPLE_DISPATCH_STATUS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_APPROVAL_INTERNAL_CONDITION_SCREEN, [
    Permission.VIEW_SAMPLE_APPROVAL_INTERNAL_CONDITION,
    Permission.EDIT_SAMPLE_APPROVAL_INTERNAL_CONDITION,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_PROCESS_SCREEN, [
    Permission.EDIT_CUP_EVALUATOR_CUPPING_PROCESS,
    Permission.VIEW_CUPPING_PROCESS,
    Permission.EDIT_CUPPING_PROCESS,
    Permission.VIEW_ALL_CUPPING_PROCESS,
    Permission.EDIT_ALL_CUPPING_PROCESS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUPPING_PROCESS_SEARCH_SCREEN, [
    Permission.VIEW_CUPPING_PROCESS_SEARCH,
    Permission.EDIT_CUPPING_PROCESS_SEARCH,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.CUP_ILLUSTRATION_SCREEN, [
    Permission.VIEW_ALL_CUPPING_PROCESS,
    Permission.EDIT_ALL_CUPPING_PROCESS,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.SAMPLE_FILE_SCREEN, [
    Permission.VIEW_SAMPLE_FILE,
    Permission.EDIT_SAMPLE_FILE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.TASTING_NOTE_SCREEN, [
    Permission.VIEW_TASTING_NOTE,
    Permission.EDIT_TASTING_NOTE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.STORAGE_SCREEN, [Permission.VIEW_STORAGE, Permission.EDIT_STORAGE, Permission.COFFEE_TASTING])
  .set(ProtectionDomain.STORAGE_TYPE_SCREEN, [
    Permission.VIEW_STORAGE_TYPE,
    Permission.EDIT_STORAGE_TYPE,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.QUALITY_ITEM_SCREEN, [
    Permission.VIEW_QUALITY_ITEM,
    Permission.EDIT_QUALITY_ITEM,
    Permission.AUTH_SERVICE,
  ])
  .set(ProtectionDomain.QUALITY_MAIN_SCREEN, [
    Permission.VIEW_QUALITY_MAIN,
    Permission.EDIT_QUALITY_MAIN,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.QUALITY_ADDITIONAL_BRANDING_SCREEN, [
    Permission.VIEW_QUALITY_MAIN,
    Permission.EDIT_QUALITY_MAIN,
    Permission.COFFEE_TASTING,
  ])
  .set(ProtectionDomain.D365_INTERFACE, [
    Permission.OVERRIDE_RESTRICTION_QUALITY_DYCO,
    Permission.OVERRIDE_RESTRICTION_THIRD_PARTY_DYCO,
  ]);
