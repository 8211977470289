<span class="nkg-block">
  <ng-container *ngIf="editable; then primaryContent; else secondContent"></ng-container>
  <ng-template #primaryContent>
    <ncs-error-tooltip [validationResult]="validationResult" class="tooltip-wrapper">
      <div class="ncs-basic-wrapper" [ngClass]="{ 'ncs-basic-wrapper-disabled': disabled }">
        <p-calendar
          [ngStyle]="{ width: '100%' }"
          [tabindex]="focusable ? (tabIndex !== undefined ? tabIndex : null) : -1"
          [disabled]="disabled"
          [(ngModel)]="primeNgDateValue"
          (onFocus)="onFocus()"
          [showIcon]="showIcon"
          [showTime]="showTime"
          [showOnFocus]="true"
          placeholder="{{ (disabledInput ? 'common.buttons.select' : 'common.formats.date.placeholder') | translate }}"
          [inline]="inline"
          [dateFormat]="dateFormat"
          [inputStyleClass]="'setFocus ' + inputStyleClass"
          [maxDate]="maxDate"
          [readonlyInput]="disabledInput"
          (onBlur)="onBlur($event)"
          (onSelect)="onSelect()"
          (onClickOutside)="onChangeDate()">
          <ng-template pTemplate="footer">
            <p-footer style="display: flex; padding: 0.5em; justify-content: space-between">
              <p-button label="{{ 'common.formats.calendar.today' | translate }}" (onClick)="onClickToday()">
              </p-button>
            </p-footer>
          </ng-template>
        </p-calendar>
      </div>
    </ncs-error-tooltip>
  </ng-template>
  <ng-template #secondContent>
    <span class="nkg-validated-value">
      {{ primeNgDateValue | date: (showTime ? Constants.getDateFormatShortTime() : dateFormatDisplay) }}</span
    >
  </ng-template>
</span>
