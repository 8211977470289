<p-button styleClass="pagination-btn" [disabled]="page === 1" (onClick)="changePage({ item: 1 })">
  <i class="material-icons">first_page</i>
</p-button>
<p-button [disabled]="page === 1" styleClass="pagination-btn" (onClick)="changePage({ page: -1 })">
  <i class="material-icons">chevron_left</i>
</p-button>
<span style="margin: 0 5px 0 10px"
  >{{ 'common.labels.total' | translate }} {{ totalItems }} - {{ 'grid.labels.page' | translate }} {{ page }}
  {{ 'grid.labels.of' | translate }} {{ lastPage }}</span
>
<p-button [disabled]="page === lastPage" styleClass="pagination-btn" (onClick)="changePage({ page: 1 })">
  <i class="material-icons">chevron_right</i>
</p-button>
<p-button [disabled]="page === lastPage" styleClass="pagination-btn" (onClick)="changePage({ item: lastPage })">
  <i class="material-icons">last_page</i>
</p-button>
