<div class="topbar clearfirx flex align-items-center justify-content-between">
  <div class="topbar-left">
    <img
      ngSrc="/assets/qcs_logo_white.png"
      alt="NCS logo"
      width="202"
      height="57"
      [priority]="true"
      (click)="displayVersionInfo = true" />
  </div>
  <div class="topbar-right flex justify-content-between">
    <div class="topbar-items flex align-items-center">
      <a tabindex="-1" *ngIf="navigateBackLink" [href]="navigateBackLink">
        <span id="menu-button"><i class="material-icons">chevron_left</i></span>
        <span id="back-to-label">{{ 'topbar.labels.back_to' | translate }} {{ navigateBackText | translate }}</span>
      </a>
      <span class="master-tittle">
        {{ view?.title | translate }}
      </span>
    </div>
    <div class="topbar-items flex align-items-center">
      <div class="flex user-agency">
        <span>{{ user?.defaultTenant?.abbreviation }}</span>
        <span>{{ user?.defaultAgency?.agency }}</span>
      </div>
      <ul class="topbar-items setting-profile animated fadeInDown flex align-items-center">
        <li>
          <a (click)="userMenu.toggle($event)" pTooltip="{{ user?.displayName }}" tooltipPosition="left">
            <i class="material-icons nkg-alert-icon">person</i>
          </a>
        </li>
        <li>
          <a (click)="onMessageItemClick()">
            <i class="topbar-icon material-icons animated swing" style="font-size: 2.4em">message</i>
            <span *ngIf="hasNewNotifications()" class="topbar-badge animated rubberBand">{{
              numberOfNewNotifications()
            }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<p-menu #userMenu popup="popup" [model]="userMenuEntries" [baseZIndex]="1500" appendTo="body"></p-menu>
<app-ncs-version-info
  [visible]="displayVersionInfo"
  (versionDialogClose)="displayVersionInfo = false"></app-ncs-version-info>

<p-toast position="center" key="session_to_expire" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="col-12">
      <div style="text-align: center" class="col-12">
        <span class="pi pi-exclamation-triangle" style="font-size: 3em; font-weight: bold"></span>
        <p style="color: #0a2617; font-weight: bold">{{ message.summary }}</p>
        <p style="color: #0a2617; font-weight: bold">{{ message.detail }}</p>
      </div>
      <div class="grid col-12 flex justify-content-between">
        <button
          pButton
          [label]="'notification.confirm' | translate"
          (click)="onReject('session_to_expire')"
          class="'p-button-success'"></button>
        <button
          pButton
          [label]="'notification.cancel' | translate"
          (click)="onConfirm('session_to_expire')"
          class="p-button-danger"></button>
      </div>
    </div>
  </ng-template>
</p-toast>
