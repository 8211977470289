import { createAction, props } from '@ngrx/store';
import { Permissions } from '../../domain/permissions.model';
import { Role } from '../../domain/role.model';
import { DirectoryUser } from '../../domain/directory-user.model';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';

export enum UserActionType {
  FETCH_ROLES = '[User] Fetch Roles',
  FETCH_ROLES_SUCCESS = '[User] Fetch Roles Success',
  FETCH_DIRECTORY_USERS = '[User] Fetch Directory Users',
  DIRECTORY_USERS_FETCHED = '[User] Directory Users Fetched',
  FETCH_PERMISSION = '[Permission] Fetch Permission',
  FETCH_PERMISSION_SUCCESS = '[Permission] Fetch Permission Success',
  RESET_USER_STORE = '[User] Reset All User And Permission Data',
}

export const fetchUserRoles = createAction(UserActionType.FETCH_ROLES);

export const fetchUserRolesSuccess = createAction(UserActionType.FETCH_ROLES_SUCCESS, props<{ roles: Role[] }>());

export const fetchDirectoryUsers = createAction(
  UserActionType.FETCH_DIRECTORY_USERS,
  props<{ query: string; pagination?: PaginationRequestParams }>(),
);

export const directoryUsersFetched = createAction(
  UserActionType.DIRECTORY_USERS_FETCHED,
  props<{ users: DirectoryUser[] }>(),
);

export const fetchPermission = createAction(UserActionType.FETCH_PERMISSION);

export const fetchPermissionSuccess = createAction(
  UserActionType.FETCH_PERMISSION_SUCCESS,
  props<{ permissions: Permissions[] }>(),
);

export const resetAllUserStore = createAction(UserActionType.RESET_USER_STORE);
