import { Entity, Serialized } from '../schema/decorators';
import { Country } from './country.model';
import { ComtrasInstance } from './comtras-instance.model';
import { Agency } from './agency.model';

import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { Permission } from './permission.model';
import { nullsafe } from '../../modules/utils/object-utils';
import { ProtectionDomain } from './protection-domain.model';
import { permissionTable } from './protection-table.model';

export enum TenantERPSystemEnum {
  DYCO = 'D365',
  COMTRAS = 'COMTRAS',
}

@Entity('administration', 'Tenant', 'auth')
export class Tenant extends AbstractVersionedEntity {
  @Serialized uuid?: string;
  @Serialized name?: string;
  @Serialized abbreviation?: string;
  @Serialized acronym?: string;
  @Serialized comtrasReference?: string;
  @Serialized tagetikCode?: string;
  @Serialized deactivated?: boolean;
  @Serialized codeGroupCompany?: string;
  @Serialized country: Country;
  @Serialized comtrasInstance: ComtrasInstance;
  @Serialized({ elementType: Agency }) agencies?: Agency[] = [];
  @Serialized erpSystem: string;

  hasRestrictionByERP(restrictions: Permission[]): Permission[] {
    // Assume to evaluate one item per tenant and per master-data
    // Put all erp restriction here
    if (this.erpSystem == TenantERPSystemEnum.DYCO) {
      return nullsafe(restrictions).filter(prm =>
        nullsafe(permissionTable.get(ProtectionDomain.D365_INTERFACE)).some(prmS => prm == prmS),
      );
    }
    return restrictions;
  }
}
