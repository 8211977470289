import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Store } from '@ngrx/store';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NkgGridFactory } from '../../services/grid.factory';
import * as fromRoot from '../../../../core/ngrx/reducers';
import { CustomDateComponent } from '../../filter/custom-date-filter/custom-date.component';
import { CustomTextFilterComponent } from '../../filter/custom-text-filter/custom-text-filter.component';
import { CustomNumberFilterComponent } from '../../filter/custom-number-filter/custom-number-filter.component';
import { CustomCountryFilterComponent } from '../../filter/custom-country-filter.component';
import { isNullOrUndefined, nullsafe } from '../../../../modules/utils/object-utils';
import { isNotEmpty } from '../../../../modules/utils/string-utils';
import { PaginationMetadata } from '../../../../core/domain/pagination-metadata.model';
import { ColumnsType } from '../../../models/columns-type.model';
import { GridSelectionMode } from '../../../models/grid-selection-mode.model';

/**
 * An ag-grid component which will update its own data every time that app.store (its data source) is updated.
 * This component uses ncs-grid wrapper and grid.factorycolDef previously created.
 * GridOptions and ColDefs could be values passed to this component and, therefore, column definitions won't be generic.
 */

@Component({
  selector: 'nkg-generic-grid',
  templateUrl: './nkg-generic-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NkgGenericGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedItem: any;
  @Input() columnsType: ColumnsType;
  @Input() rowData: any[];
  @Input() colDef: ColDef[];
  @Input() gridOptions: GridOptions;
  @Input() editMode: boolean = false;
  @Input() selectionMode: string = GridSelectionMode.SINGLE;
  @Input() rowId: string;
  @Input() loading: boolean;
  @Input() quickFilterEnabled: boolean = false;
  @Input() sizeToFit: boolean;
  @Input() tooltipSearchLabel: string = 'grid.labels.searchOoo';
  @Input() agGridStyle: any = {
    width: '100%',
    'max-height': '600px',
  };
  @Input() wrappedInCard: boolean = true;
  @Input() localFilter: boolean = false; // search for sampleThirdPartyTypes here or emit onSearchEntered
  @Input() forceMultiselectMode: boolean = false;
  /** When selecting other master-data it's necessary to force the update of the settings for the current grid. */
  @Input() gridViewMasterReference: string = '';
  @Input() paginationResponse: PaginationMetadata;
  @Input() contextMenuItems: any;
  @Input() toExpand!: boolean;
  @Input() enabledFooter: boolean = true;
  @Input() autoGroupColumnDef: any;

  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowDoubleClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() gridReady: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowSelections: EventEmitter<any> = new EventEmitter<any>();
  @Output() onQuickFilterSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowDragEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowDragEnter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterSortChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onLeaveDragDrop: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangePage: EventEmitter<number> = new EventEmitter<number>();

  private readonly viewSubscription$: Subscription;
  private rowClickTimer: any = null;
  private hasDoubleClickFired: boolean = false;
  public frameworkComponents: any;
  public isRowDataEmpty: boolean = false;
  public quickFilter: string = '';

  constructor(
    protected gf: NkgGridFactory,
    private readonly cd: ChangeDetectorRef,
    private readonly store: Store<fromRoot.State>,
  ) {
    this.viewSubscription$ = this.gridViewsSubscription();

    /** @See https://www.ag-grid.com/documentation/angular/component-filter/ */
    this.frameworkComponents = {
      agDateInput: CustomDateComponent,
      customTextFilter: CustomTextFilterComponent,
      customNumberFilter: CustomNumberFilterComponent,
      customCountryFilter: CustomCountryFilterComponent,
    };
  }

  public getContextMenuItems = (params: any): any => {
    const menu = params.defaultItems.slice(0);
    if (this.contextMenuItems) {
      return this.contextMenuItems;
    }
    return menu;
  };

  public onRowDragEnd(event: any): void {
    this.rowDragEnd.emit(event);
  }

  public onRowDragEnter(event: any): void {
    this.rowDragEnter.emit(event);
  }

  private gridViewsSubscription(): Subscription {
    return combineLatest([
      this.store.select(fromRoot.getCurrentGridView).pipe(filter(grid => !isNullOrUndefined(grid))),
      this.store.select(fromRoot.getGridViewReference),
    ]).subscribe(([view, viewReference]) => {
      if (
        this.gridOptions &&
        isNotEmpty(this.gridViewMasterReference) &&
        this.gridViewMasterReference === viewReference
      ) {
        // WARN: IF gridView don't set {viewsDefaultColumnState}, is undefined, so when retrieve data is undefined too
        // and agGrid setting columns to empty and displayed nothing. Check setting correctly on GridViewsComponent
        setTimeout(() => {
          this.gridOptions?.columnApi?.setColumnState(view.viewsDefaultColumnState);
          this.gridOptions?.api?.setSortModel(view.sorting);
          this.gridOptions?.api?.setFilterModel(view.filter);
          this.loading = false;
        }, 300);
      }
    });
  }

  ngOnInit(): void {
    if (isNullOrUndefined(this.gridOptions)) {
      this.gridOptions = this.createGridOptions();
    } else {
      this.rowData = this.gridOptions.rowData;
    }
  }

  ngOnDestroy(): void {
    if (!isNullOrUndefined(this.rowClickTimer)) clearTimeout(this.rowClickTimer);
    this.viewSubscription$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quickFilter) {
      if (!nullsafe(this.gridOptions.columnDefs).length) {
        this.gridOptions.defaultColDef.filter = false;
      } else {
        this.gridOptions.defaultColDef.filter = this.quickFilterEnabled;
      }
    }
    if (changes.agGridColumns || changes.gridOptions) {
      this.doGridRender();
      if (this.gridOptions?.api && this.sizeToFit) {
        this.gridOptions.api?.sizeColumnsToFit();
      } else if (this.gridOptions.columnApi) {
        this.gridOptions.columnApi.autoSizeAllColumns();
      }
    }

    // WARN: If set grid's row data, remove configs itself and rendering data again.
    if (changes.rowData && changes.rowData.currentValue !== changes.rowData.previousValue) {
      this.doGridRender();
    }
  }

  // function made to detect the changes on columns in the grid
  public doGridRender(): void {
    this.cd.detectChanges();
    this.cd.markForCheck();
    this.forceSelectedRow();
  }

  public changePage(event: number): void {
    this.onChangePage.emit(event);
  }

  private createGridOptions(): GridOptions {
    const gOptions = this.gf.getDefaultGridOptions(this.sizeToFit, false, this);
    gOptions.columnDefs = [
      {
        rowDrag: true,
      },
    ];

    gOptions.suppressColumnVirtualisation = true;
    gOptions.deltaRowDataMode = true;

    if (!this.rowId) throw Error('You have not specified Internal ID for data.');
    gOptions.getRowNodeId = (data): any => nullsafe(data)[this.rowId];

    return gOptions;
  }

  public modelUpdated(): void {
    setTimeout(() => (this.isRowDataEmpty = this.gridOptions?.api?.getDisplayedRowCount() <= 0));
    setTimeout(() => this.gridOptions?.columnApi?.autoSizeAllColumns(), 300);
    if (!this.forceMultiselectMode) {
      this.forceSelectedRow();
    }
  }

  public onGridReady(params: any): void {
    this.gridReady.emit(params);
  }

  public onRowDoubleClick(event: any): void {
    if (this.selectionMode !== GridSelectionMode.MULTIPLE) {
      this.hasDoubleClickFired = true;
      this.rowDoubleClicked.emit(event);
    }
  }

  public onSearchKeyup(event: any): void {
    // Creates new search: enter typed or backspace typed and empty target
    const eventValue = event?.target?.value || '';
    if (event.keyCode === 13 /* ENTER */ || event.keyCode == 8 /* BACKSPACE */) {
      this.onQuickFilterSearch.emit(eventValue);
    }
    // Fire or not even search emitter.
    // If !localFilter, then fire event.
    // If not, search in all rowData provided
    if (this.localFilter) {
      this.quickFilter = event.target.value || '';
    }
  }

  /**
   * setTimeout is used because gridRowClick and rowDoubleClick on grid are fired.
   * GridRowClick and doubleRowClick on ag-grid-angular are fired at the same time, no matter
   * if user has only double-clicked on a row, both events are fired.
   * Therefore, we wait half a second to verify if there
   * was a double click or not.
   * --- 500ms = maximum time for double click
   * */
  public onRowClick(event: any): void {
    // emmit event to parent component
    this.rowClickTimer = setTimeout(() => {
      if (!this.hasDoubleClickFired) {
        this.selectedItem = event.data;
        this.rowClicked.emit(event);
        if (!this.forceMultiselectMode) {
          this.forceSelectedRow();
        }
      } else this.hasDoubleClickFired = false;
      clearTimeout(this.rowClickTimer);
    }, 500);
  }

  public forceSelectedRow(): void {
    if (!isNullOrUndefined(this.gridOptions?.api)) {
      this.gridOptions.api.forEachNode(node => node.setSelected(node.data === this.selectedItem));
    }
  }

  public onSelectionRow(event: any): void {
    const selection = event.api?.getSelectedRows();
    if (!isNullOrUndefined(selection)) {
      this.rowSelections.emit(selection);
    }
  }

  public onSortOrFilterChanges(): void {
    // {type,api,columnApi}
    // WARN: Filter changes are reflected via api and all of them can be displayed by getFilterModel()
    this.onFilterSortChanges.emit();
  }

  public onRowLeave(): void {
    this.onLeaveDragDrop.emit();
  }
}
