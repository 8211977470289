export enum ProtectionDomain {
  ADMIN_SCREEN,
  MASTER_DATA_SCREEN,
  ROLE_SCREEN,
  SAMPLE_TYPE_SCREEN,
  LANGUAGE_SCREEN,
  COUNTRY_SCREEN,
  REGION_SCREEN,
  STATE_SCREEN,
  COFFEE_SPECIE_SCREEN,
  COFFEE_VARIETY_SCREEN,
  USER_SCREEN,
  TENANT_SCREEN,
  AGENCY_SCREEN,
  WAREHOUSE_SCREEN,
  HARVEST_METHOD_SCREEN,
  SOIL_TYPE_SCREEN,
  SAMPLE_STATUS_SCREEN,
  COURIER_COMPANY_SCREEN,
  CONTACT_TYPE_SCREEN,
  STAGE_PROCESSING_SCREEN,
  MILLING_PROCESS_SCREEN,
  DRYING_PROCESS_SCREEN,
  THIRD_PARTY_SCREEN,
  THIRD_PARTY_TYPE_SCREEN,
  CUP_EVALUATOR_SCREEN,
  COFFEE_PROGRAM_SCREEN,
  METRIC_TYPE_SCREEN,
  METRIC_TYPE_RESULTS_SCREEN,
  METRIC_GROUP_SCREEN,
  METRIC_DEFINITION_SCREEN,
  STANDARD_DEFINITION_SCREEN,
  QUALITY_SCREEN,
  FACT_SHEETS_SCREEN,
  FACT_SHEET_SCREEN,
  TASTING_NOTE_SCREEN,
  QUALITY_TYPE_SCREEN,
  ITEM_DEFINITION_SCREEN,
  CHECK_LIST_DEFINITION_SCREEN,
  ADDRESS_TYPE_SCREEN,
  SAMPLE_SCREEN,
  PACKAGE_TYPE_SCREEN,
  COFFEE_CERTIFICATION_SCREEN,
  LABORATORY_SCREEN,
  CUPPING_SESSION_SCREEN,
  CUPPING_SESSION_STATUS_SCREEN,
  SAMPLE_DISPATCH_STATUS_SCREEN,
  CUPPING_PROCESS_SCREEN,
  CUPPING_PROCESS_SEARCH_SCREEN,
  SAMPLE_APPROVAL_INTERNAL_CONDITION_SCREEN,
  CUP_ILLUSTRATION_SCREEN,
  SAMPLE_FILE_SCREEN,
  STORAGE_SCREEN,
  STORAGE_TYPE_SCREEN,
  QUALITY_ITEM_SCREEN,
  QUALITY_MAIN_SCREEN,
  QUALITY_ADDITIONAL_BRANDING_SCREEN,
  D365_INTERFACE,
}
