import { Entity, Internal, Serialized } from '../schema/decorators';
import { AbstractVersionedEntity } from './abstract-versioned-entity.model';
import { CuppingSessionStatus } from './cupping-session-status.model';
import { Laboratory } from './laboratory.model';
import { StandardDefinition } from './standard-definition.model';
import { MetricsDefinitionType } from './metrics-definition-type.model';
import { CuppingSessionSample } from './cupping-session-sample.model';
import { CuppingSessionCupEvaluator } from './cupping-session-cup-evaluator.model';
import { CuppingProcess } from './cupping-process.model';
import { CupIllustration } from './cup-illustration.model';

@Entity('cupping', 'CuppingSession', 'qc')
export class CuppingSession extends AbstractVersionedEntity {
  @Serialized idSession: string;
  @Serialized session: string;
  @Serialized blind: boolean = false;
  @Serialized doAverage: boolean = false;
  @Serialized cuppingDate: Date;
  @Serialized status: CuppingSessionStatus;
  @Serialized laboratory: Laboratory;
  @Serialized standardDefinition: StandardDefinition;
  @Serialized({ elementType: MetricsDefinitionType })
  metricsDefinitionTypes?: MetricsDefinitionType[] = [];
  @Serialized({
    elementType: CuppingSessionSample,
    composite: true,
  })
  cuppingSessionSamples: CuppingSessionSample[] = [];
  @Serialized({
    elementType: CuppingSessionCupEvaluator,
    composite: true,
  })
  cuppingSessionCupEvaluators?: CuppingSessionCupEvaluator[] = [];
  @Serialized({ elementType: CuppingProcess, composite: true })
  cuppingProcesses?: CuppingProcess[] = [];
  @Serialized sampleWeight: number;
  @Serialized totalCups: number;
  @Serialized cupIllustration: CupIllustration;
  @Internal useAllCupEvaluators: boolean = false;
  @Internal guestEvaluators: CuppingSessionCupEvaluator[] = [];
}
