import { Injectable } from '@angular/core';
import { clone as _clone } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AuthorizationService } from './authorization.service';
import { TimeService } from './time-service';
import 'reflect-metadata';
import {
  isNullOrUndefined,
  mergeImmutable,
  nullsafe,
  ObjectPath,
  toPathSpec,
} from '../../../modules/utils/object-utils';
import { createNew, TYPE_KEY } from '../../schema/decorators';
import { AbstractCeisService } from './app.abstract.service';
import { convertDate } from '../../../shared/basic-shared-module/utils/date-utils';
import { Constants } from '../../utils/app.constants';
import { AbstractEntity } from '../../domain/abstract-entity.model';
import { PaginationRequestParams } from '../../domain/pagination-request-params.model';
import { EmailAlert } from '../../domain/email-alert.model';
import { TypeInfo } from '../../schema/type-info.model';
import { JsonPropertyAccess } from '../../schema/json-property-access.model';
import { OptionMenu } from '../../domain/option-menu.model';
import { isEmpty } from '../../../modules/utils/string-utils';
import { SchemasService } from '../../schema/schema';

@Injectable({
  providedIn: 'root',
})
export class QueryService extends AbstractCeisService {
  private readonly getUrlMenus: string;
  private readonly getUrlLang: string;
  private readonly getUrlTranslations: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    timeService: TimeService,
    private readonly authService: AuthorizationService,
    private readonly schemaService: SchemasService,
  ) {
    super(http, configuration, timeService);
    this.getUrlMenus = `${configuration.SERVER_WITH_AUTH_API_URL}rest/menus`;
    this.getUrlLang = configuration.LANG_QUERY_URL;
    this.getUrlTranslations = configuration.TRANSLATIONS_URL;
  }

  public getUrlForEntity(entityName: string, serviceUrl?: string): string {
    if (isEmpty(entityName)) {
      return null;
    }
    const entityPrefix = entityName.split('.');

    const entityUrl =
      serviceUrl ||
      entityPrefix[entityPrefix.length - 1]
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();
    if (entityPrefix[0] === 'auth') {
      return `${this.configuration.SERVER_WITH_AUTH_API_URL}${entityUrl}`;
    }
    if (entityPrefix[0] === 'qc') {
      return `${this.configuration.SERVER_WITH_QC_API_URL}${entityUrl}`;
    }
  }

  /**
   * Convert the JSON payload object received from a server response to the internal data
   * structure based on the static type information of the known types.
   *
   * @returns {any} the returned client-side object
   */
  public fromServer(json: any, type?: TypeInfo): any {
    if (isNullOrUndefined(json)) return json;
    if (Array.isArray(json) && isNullOrUndefined(type)) return json.map(v => this.fromServer(v));
    /* Check whether we have a type property */
    const typeName = json[TYPE_KEY];
    if (typeName === undefined) {
      if (type === undefined)
        throw new Error(
          `JSON object [${JSON.stringify(json)}] does not define ${TYPE_KEY} property. Cannot map from server.`,
        );
    } else {
      type = this.schemaService.getSchemaOf(typeName);
      if (type === undefined) {
        throw new Error(`Type [${typeName}] is not known. Cannot map from server.`);
      }
    }
    return this.onGetPrimitiveType(type, json);
  }

  private onGetPrimitiveType(type: TypeInfo, json: any): any {
    if (type.ctor === Date) {
      if (typeof json === 'string' || typeof json === 'number') {
        /* It is an ISO 8601 formatted date value. Parse it. */
        return convertDate(<string>json);
      }
      throw new Error(
        `Expected Date value [${JSON.stringify(
          json,
        )}] to be of type String or Number, but was [${typeof json}]. Cannot map from server.`,
      );
    } else if (type.isPrimitive) {
      /* It is a primitive value, return as is. */
      return json;
    } else if (type.isKnownClass) {
      /* It is an instance of a known class. Map properties. */
      return this.fromServerClass(json, type);
    } else if (type.isArray) {
      /* It is an array. Use the elementType. */
      return json.map((e: any) => this.fromServer(e, type.elementType));
    } else {
      throw new Error(
        `Cannot handle JSON ${JSON.stringify(
          json,
        )} of type ${typeof type}. Probably @Entity or @DTO decorator/annotation missing. Cannot map to server.`,
      );
    }
  }

  private fromServerClass(json: any, type: TypeInfo): any {
    if (typeof json === 'number') {
      // Unresolved referenced entity, just return a new object with the id
      return createNew(type.ctor, json);
    }
    const result = createNew(type.ctor, json.id);

    Array.from(type.properties.entries()).forEach(e => {
      const propertyName = e[0];
      const propertyType = e[1];
      const isSerializedOrNotReadOnly =
        !propertyType.isSerialized || propertyType.access === JsonPropertyAccess.READ_ONLY;
      /* We do not want to deserialize this property
      /* Ignore the JSON does not contain that property
      */
      if (!isSerializedOrNotReadOnly && propertyName in json) {
        const actualValue = json[propertyName];
        if (propertyType.type.isKnownClass && typeof actualValue === 'number') {
          /* It is an ID without a type */
          result[propertyName] = createNew(propertyType.type.ctor, actualValue);
        } else {
          result[propertyName] = this.fromServer(actualValue, propertyType.type);
        }
      }
    });
    return result;
  }

  /**
   * Convert the client-side JavaScript object 'value' into a JSON payload structure to be sent to the server
   * based on the static type information of the known types.
   *
   * @param value the JavaScript object to be converted to a suitable object to create a JSON object from
   * @param composite whether this value is part of a composite and should not be serialized as a reference
   * @param {TypeInfo} type the optional TypeInfo of the value. If this is omitted, 'value' must be an object with
   *                   a type property whose value is the type name
   * @param root whether this is the root object (i.e. the first call to toServer())
   * @returns {any} the object to be sent to the server as JSON
   */
  public toServer(value: any, composite: boolean = true, type?: TypeInfo, root: boolean = true): any {
    if (isNullOrUndefined(value)) return value;
    if (Array.isArray(value) && isNullOrUndefined(type)) return value.map(v => this.toServer(v, composite, type, root));
    /* Check whether we have a type property */
    const typeName = value[TYPE_KEY];
    if (isNullOrUndefined(typeName)) {
      if (isNullOrUndefined(type)) {
        throw new Error(
          `Object [${JSON.stringify(value)}] does not define ${TYPE_KEY} property. Cannot map to server.`,
        );
      }
    } else {
      type = this.schemaService.getSchemaOf(typeName);
      if (isNullOrUndefined(type)) {
        throw new Error(`Type [${typeName}] is not known. Cannot map to server.`);
      }
    }
    if (isNullOrUndefined(type)) {
      throw new Error(`Type [${typeName}] is not a known type. Cannot map to server.`);
    }
    if (type.ctor === Date) {
      /* It is a Date value. Format ISO 8601 string. */
      return convertDate(value);
    }
    if (type.isPrimitive) {
      /* It is a primitive value. Return as is. */
      return value;
    }
    if (type.isKnownClass) {
      /* It is an instance of a known class. Map properties. */
      return this.toServerClass(value, composite, type, true);
    }
    if (type.isArray) {
      /* It is an array. Use the elementType. */
      return value.map((e: any) => this.toServer(e, composite, type.elementType, root));
    }
    throw new Error(
      `Cannot handle value ${JSON.stringify(
        value,
      )} of type ${typeof type}. Probably @Entity or @DTO decorator/annotation missing. Cannot map to server.`,
    );
  }

  private static toRef(value: any, type: TypeInfo): any {
    if (type.ctor === value.constructor) {
      /* Static type is exactly equal to runtime type. Serialize only id. */
      return value.id;
    }
    /* Serialize full Ref (id, type, __ref) */
    // return {[TYPE_KEY]: value[TYPE_KEY], id: value.id, __ref: true}; [QC-345]
    return value.id;
  }

  private toServerClass(value: any, composite: boolean, type: TypeInfo, root: boolean): any {
    if (typeof value === 'number') {
      // Unresolved reference -> keep as it is
      return value;
    }
    // QC-115: Disable because don't typing nested sampleThirdPartyTypes
    if (!composite && value?.id > 0) {
      /* Serialize as reference */
      return QueryService.toRef(value, type);
    }
    const result: any = {};
    /* Include the __type property if this is the root object or the class is unexpected */
    // QC-115
    if (root || value.constructor !== type.ctor) {
      result[TYPE_KEY] = value[TYPE_KEY];
    }
    result[TYPE_KEY] = value[TYPE_KEY];
    /* Iterate over all static properties */
    Array.from(type.properties.entries()).forEach(e => {
      const propertyName = e[0];
      const propertyType = e[1];
      const isSerializedOrNotWriteOnly =
        !propertyType.isSerialized || propertyType.access === JsonPropertyAccess.WRITE_ONLY;
      /* We do not want to serialize this property
      /* the property has not been assigned, and we do not care about its value
       */
      if (!isSerializedOrNotWriteOnly && propertyName in value) {
        let actualValue = value[propertyName];
        /* Check that the value is set to undefined */
        if (isNullOrUndefined(actualValue)) {
          if (!isNullOrUndefined(propertyType.default)) {
            /* This property has a default value. Set it. */
            actualValue = propertyType.default;
          } else {
            /* Undefined values are not transferred to backend ({field: undefined} |json) => {}.
             * To delete a value, it must be set to 'null' due to versioning.
             */
            actualValue = null;
          }
        }
        const { composite: compositeIn } = propertyType;
        result[propertyName] = this.toServer(actualValue, !!compositeIn, propertyType.type, false);
      }
    });
    return result;
  }

  public async obtainMenus(): Promise<OptionMenu[]> {
    return this.get(`${this.getUrlMenus}/obtain-menus`, true, () => this.authService.renewToken());
  }

  /**
   * This backend result is object's array, this first value is the total records and second value is a set records for search
   * @param entityType
   * @param searchParams
   * @param serviceUrl
   * @param fields
   * @param include
   * @param currentId
   * @param useTranslations
   * @returns {Promise<any[]>}
   */
  public async querySearch<T>(
    entityType: Function,
    searchParams?: PaginationRequestParams,
    serviceUrl?: string,
    fields?: ObjectPath,
    include?: ObjectPath,
    currentId?: number,
    useTranslations?: boolean,
  ): Promise<T> {
    const typeInfo: TypeInfo = this.schemaService.getSchemaOf(entityType.prototype[TYPE_KEY]);
    if (isNullOrUndefined(typeInfo)) {
      throw new Error(`Type [${entityType.prototype[TYPE_KEY]}] is not a known type. Cannot map to server.`);
    }
    let urlTranslations = '';
    let langParam = '';
    if (useTranslations) {
      urlTranslations = this.getUrlTranslations;
    } else {
      const isoCode: string = this.getLanguage();
      langParam = isEmpty(isoCode) ? '' : this.getUrlLang + isoCode;
    }
    const currentIdParam = !isNullOrUndefined(currentId) ? `${currentId}/` : '';

    if (!isNullOrUndefined(searchParams)) {
      const newColumns = !isNullOrUndefined(fields) ? toPathSpec(fields) : searchParams.columns;
      const newInclude = !isNullOrUndefined(include) ? toPathSpec(include) : searchParams._include;
      searchParams = mergeImmutable(_clone(searchParams), {
        columns: newColumns,
        _include: newInclude,
      });
    }
    // PaginatedResponse or T
    let resp = await this.getWithParams<any>(
      `${this.getUrlForEntity(entityType.prototype[TYPE_KEY], serviceUrl)}/${currentIdParam}${urlTranslations}${langParam}`,
      searchParams,
      true,
      () => this.renewToken(),
    );
    if (nullsafe(resp?.items).length > 0) {
      resp = mergeImmutable(resp, { items: this.fromServer(resp?.items) });
    }
    return <T>resp;
  }

  public getEntityById<T>(
    entityType: Function,
    id?: number,
    paginationRequestParams?: PaginationRequestParams,
  ): Promise<T> {
    if (!paginationRequestParams) {
      paginationRequestParams = new PaginationRequestParams();
      paginationRequestParams._include = '*';
      paginationRequestParams.size = null;
    }

    return this.querySearch<T>(entityType, paginationRequestParams, null, null, null, id, false);
  }

  public async saveEntity<T extends AbstractEntity>(
    entity: T,
    entityTypeFunction: Function,
    params?: any,
    isoCode?: string,
  ): Promise<T> {
    if (isNullOrUndefined(entity)) {
      return null;
    }
    const langParam = isNullOrUndefined(isoCode) ? '' : this.getUrlLang + isoCode;

    let entityObject = this.toServer(entity);

    if (isoCode) {
      entityObject = this.setLanguage(entityObject, isoCode);
    } else {
      entityObject.lang = this.getLanguage();
    }

    if (entity.id > 0) {
      return this.putWithParams(
        `${this.getUrlForEntity(entityTypeFunction.prototype[TYPE_KEY])}/${langParam}`,
        params,
        entityObject,
        true,
        () => this.authService.renewToken(),
      );
    }
    return this.postWithParams(
      `${this.getUrlForEntity(entityTypeFunction.prototype[TYPE_KEY])}/${langParam}`,
      params,
      entityObject,
      true,
    );
  }

  public async emailAlert<T>(emailAlert: EmailAlert, entityTypeFunction: Function, include?: any): Promise<any> {
    if (isNullOrUndefined(emailAlert)) {
      return null;
    }
    const entityUrl = this.getUrlForEntity(entityTypeFunction.prototype[TYPE_KEY]);
    const url = `${entityUrl}/${emailAlert.id}/notify/${emailAlert.code}`;
    include = { language: this.getLanguage(), ...include };
    const resp = await this.postWithParams<T>(url, include, { _include: toPathSpec({}) }, true);
    return <T>resp;
  }

  private setLanguage<T extends AbstractEntity>(entity: T, lang: string): T {
    Object.keys(entity).forEach(key => {
      const value = entity[key];
      if (Array.isArray(value) || typeof value == 'object') delete entity[key];
    });
    entity.lang = lang;
    return entity;
  }
}
