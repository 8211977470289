<base-master-data-form
  [gridSelectedRow]="currentItem"
  [gridOptions]="gridOptions"
  [disabledColumns]="disabledGridViewColumns"
  [gridColumns]="gridColDef"
  [editMode]="editMode"
  [gridViewNameReference]="gridViewNameReference"
  [typeFunction]="typeFunction"
  [currentViewMode]="formMasterViewMode"
  [currentModified]="currentModified"
  [sizeToFit]="sizeToFit"
  [permissionAvailable]="[permission.EDIT_CUPPING_SESSION]"
  [navigationKey]="navigationIdPath"
  [paginationResponse]="paginationResponse"
  [paginationRequestParams]="paginationRequestParams"
  [listMode]="listModeDefault"
  (gridRowClick)="rowClick($event)"
  (newClicked)="onNew()"
  (cancelClicked)="onCancel($event)"
  (saveClicked)="onSaveClicked()"
  (onSearchEntered)="onSearchQuickEntered($event)"
  (onQuickFilterSearch)="onFilterByGrid($event)"
  (editClicked)="switchEditMasterMode($event)"
  (showRetiredClicked)="onSearchRetiredChanged($event)"
  (onBackTo)="onBackTo()"
  (changePage)="onChangePage($event)">
  <div base-master="form" class="col-12 cupping-session">
    <div class="col-12">
      <div *ngIf="!currentItem" class="col-12">
        <small>{{ 'common.masterdetail.nothing_selected' | translate }}</small>
      </div>
      <div *ngIf="!!currentItem">
        <div class="title">
          {{
            (currentItem?.session || '-') +
              (selectedStandardDefinition ? ' (' + selectedStandardDefinition?.code + ') ' : '') +
              (currentModified ? ' (' + ('common.modified' | translate) + ')' : '')
          }}
          <span *ngIf="currentItem?.id > 0" style="float: right">
            <ncs-checkbox-basic
              [disabled]="!editMode"
              [title]="'common.tooltips.retired'"
              [value]="currentItem.deleted"
              [label]="'common.labels.retired'"
              (onChange)="onItemFieldChange($event, 'deleted')">
            </ncs-checkbox-basic>
          </span>
        </div>
        <p-tabView class="ncs-tabview" (activeIndexChange)="activeIndexChange($event)" [(activeIndex)]="tabViewIndex">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <span class="p-tabview-title" [attr.section-with-errors]="!isValidSampleData()">
                {{ 'common.labels.basic_information' | translate }}
              </span>
            </ng-template>
            <div class="grid col-12">
              <div class="grid col-12 xl:col-9 lg:col-9 md:col-12 sm:col-12">
                <div class="grid col-12">
                  <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-10">
                    <ncs-text-basic
                      [maxCharacterLength]="100"
                      [label]="'common.labels.id_session'"
                      [disabled]="true"
                      [value]="currentItem?.idSession || ''">
                    </ncs-text-basic>
                  </div>
                  <div class="col-12 xl:col-4 lg:col-5 md:col-6 sm:col-6">
                    <ncs-text-basic
                      [editable]="editMode"
                      [maxCharacterLength]="100"
                      [label]="'common.labels.session'"
                      [value]="currentItem?.session || ''"
                      [validationResult]="validationResult?.fieldResults?.session"
                      (onchange)="onItemFieldChange($event, 'session')"
                      (onblur)="onItemFieldBlur('session')">
                    </ncs-text-basic>
                  </div>
                  <div class="col-12 xl:col-3 lg:col-3 md:col-2 sm:col-6">
                    <ncs-date
                      [formatType]="'datetime'"
                      [showTime]="true"
                      [inputStyleClass]="'font-weight: bold'"
                      [label]="'common.labels.date'"
                      [editable]="editMode"
                      [value]="currentItem?.cuppingDate"
                      (onchange)="onItemFieldChange($event, 'cuppingDate')"
                      (onblur)="onItemFieldBlur('cuppingDate')">
                    </ncs-date>
                  </div>
                </div>
                <div class="grid col-12">
                  <div class="col-12 xl:col-4 lg:col-4 md:col-4 sm:col-6">
                    <ncs-selectbox
                      [label]="'common.labels.laboratory'"
                      [editable]="true"
                      [options]="laboratoryOptions"
                      [value]="currentItem?.laboratory"
                      [fieldReadOnly]="'name'"
                      [isEmptyAllowed]="false"
                      [validationResult]="validationResult?.fieldResults?.laboratory"
                      (onblur)="onItemFieldBlur('laboratory')"
                      (onSelect)="onItemFieldChange($event, 'laboratory')">
                    </ncs-selectbox>
                  </div>
                  <div class="col-12 xl:col-4 lg:col-5 md:col-6 sm:col-6">
                    <ncs-lookup-search
                      [label]="'common.labels.standard_definition_sensory'"
                      [titleGrid]="'common.labels.standard_definition_sensory'"
                      [btnMagnifier]="true"
                      [colDefArray]="['name', 'code']"
                      [suggestions]="standardDefinitionSuggestions"
                      [value]="currentItem?.standardDefinition"
                      [editable]="editMode"
                      [fieldReadOnly]="'name'"
                      [validationResult]="validationResult?.fieldResults?.standardDefinition"
                      (onCompleteMethod)="loadStandardDefinitionsSuggestions($event?.query)"
                      (onSelected)="onItemFieldChange($event, 'standardDefinition'); onSetCupByStandard($event)"
                      (onchange)="onItemFieldChange($event, 'standardDefinition'); onSetCupByStandard($event)"
                      (onblur)="onItemFieldBlur('standardDefinition')">
                    </ncs-lookup-search>
                  </div>
                  <div class="col-12 xl:col-3 lg:col-3 md:col-2 sm:col-3">
                    <ncs-number-input
                      [disabled]="!editMode"
                      [editable]="editMode"
                      [minValue]="3"
                      [maxValue]="15"
                      [maxInteger]="2"
                      [label]="'common.labels.cups'"
                      [value]="currentItem?.totalCups"
                      [validationResult]="validationResult?.fieldResults?.totalCups"
                      (onblur)="onItemFieldBlur('totalCups')"
                      (onchange)="onChangeTotalCups($event)">
                    </ncs-number-input>
                  </div>
                  <div class="col-12 xl:col-4 lg:col-6 md:col-7 sm:col-9">
                    <ncs-select-button
                      [label]="'common.labels.status'"
                      [options]="cuppingStatus"
                      [disabled]="!editMode"
                      [value]="currentItem?.status"
                      (onchange)="onItemFieldChange($event, 'status')"
                      (blur)="onItemFieldBlur('status')"></ncs-select-button>
                  </div>
                  <div
                    class="col-12 xl:col-offset-0 lg:col-offset-1 xl:col-2 lg:col-2 md:col-3 sm:col-6 flex align-items-end">
                    <ncs-number-input
                      [label]="'common.labels.sample_weight'"
                      [validationResult]="validationResult?.fieldResults?.sampleWeight"
                      [editable]="editMode"
                      [minValue]="50"
                      [value]="currentItem?.sampleWeight"
                      (onchange)="onItemFieldChange($event, 'sampleWeight')">
                    </ncs-number-input>
                  </div>
                  <div class="col-12 xl:col-2 lg:col-2 md:col-3 sm:col-6 flex align-items-end">
                    <ncs-checkbox-basic
                      [editable]="editMode"
                      [title]="'common.tooltips.blind'"
                      [value]="currentItem?.blind"
                      [label]="'common.labels.blind'"
                      (onChange)="onItemFieldChange($event, 'blind')">
                    </ncs-checkbox-basic>
                  </div>
                  <div class="col-12 xl:col-2 lg:col-3 md:col-3 sm:col-6 flex align-items-end">
                    <ncs-checkbox-basic
                      [editable]="editMode"
                      [title]="'common.labels.do_average'"
                      [value]="currentItem?.doAverage"
                      [label]="'common.labels.do_average'"
                      (onChange)="onItemFieldChange($event, 'doAverage')">
                    </ncs-checkbox-basic>
                  </div>
                </div>
              </div>
              <div class="col-12 xl:col-3 lg:col-3 md:col-12 sm:col-12">
                <p-fieldset [style]="{ width: '100%', height: '220px' }">
                  <p-header>
                    <span> {{ 'common.labels.cup_illustration' | translate }} </span>&nbsp;
                    <span *ngIf="currentItem?.totalCups > 0">
                      ({{ currentItem?.totalCups }}&nbsp;{{ 'common.labels.cups' | translate }})
                    </span>
                  </p-header>
                  <div class="flex justify-content-between flex-wrap">
                    <div
                      class="flex justify-content-start"
                      *ngIf="currentItem?.cupIllustration?.cupIllustrationImages[0]">
                      <img
                        width="300"
                        height="200"
                        [src]="currentItem?.cupIllustration?.cupIllustrationImages[0]?.path"
                        class="cup-image"
                        alt="not-available" />
                    </div>
                    <div class="flex justify-content-end align-items-center">
                      <p-button
                        pTooltip="{{ 'common.buttons.add_image' | translate }}"
                        icon="pi pi-images"
                        [disabled]="!editMode || !isValidForSearches()"
                        (onClick)="onLoadImages()">
                      </p-button>
                    </div>
                  </div>
                </p-fieldset>
              </div>
              <div class="grid col-12">
                <div
                  class="col-12 xl:col-4 lg:col-4 md:col-6 sm:col-6"
                  *ngIf="currentItem?.id && currentItem.id > 0 && !editMode">
                  <app-enter-cupping-session
                    [cuppingSessionId]="currentItem.id"
                    [cuppingProcessType]="cuppingProcessType.CUPPING_SESSION">
                  </app-enter-cupping-session>
                </div>
                <div
                  class="col-12 lg:col-offset-4 md:col-offset-2 lg:col-4 xl:col-4 md:col-4 p-sm-offset-0 sm:col-6"
                  style="text-align: end"
                  *ngIf="!editMode && currentItem?.cuppingSessionCupEvaluators?.length">
                  <ncs-button
                    [label]="'common.labels.invite'"
                    [icon]="'fas fa-envelope'"
                    (onClicked)="inviteEvaluators()"></ncs-button>
                </div>
              </div>
              <div class="col-12">
                <p-accordion>
                  <p-accordionTab>
                    <p-header>
                      <span
                        [attr.section-with-errors]="
                          isValidationFailure(validationResult?.fieldResults?.cuppingSessionSamples)
                        ">
                        {{ 'common.labels.sample' | translate }}
                      </span>
                    </p-header>
                    <app-cupping-session-sample
                      [validationResult]="validationResult"
                      (onblur)="onItemFieldBlur('cuppingSessionSamples')"></app-cupping-session-sample>
                  </p-accordionTab>
                </p-accordion>
              </div>
              <div class="col-12">
                <p-accordion>
                  <p-accordionTab>
                    <p-header>
                      <span
                        [attr.section-with-errors]="
                          isValidationFailure(validationResult?.fieldResults?.cuppingSessionCupEvaluators)
                        ">
                        {{ 'common.labels.cup_evaluators' | translate }}
                      </span>
                    </p-header>
                    <app-cupping-session-evaluators
                      [validationResult]="validationResult"
                      (onblur)="onItemFieldBlur('cuppingSessionCupEvaluators')">
                    </app-cupping-session-evaluators>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </p-tabPanel>

          <p-tabPanel header="{{ 'common.labels.averages' | translate }}" *ngIf="currentItemId > 0">
            <app-cupping-session-average></app-cupping-session-average>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</base-master-data-form>

<ncs-dialog-basic
  [headerText]="'common.labels.cup_illustration'"
  [visible]="showImageDialog"
  [showOnModal]="true"
  [primaryButtonShow]="false"
  [secondaryButtonText]="'common.buttons.close'"
  class="upload-gallery-cup-image"
  (onHide)="showImageDialog = false"
  (onSecondaryClicked)="showImageDialog = false">
  <p-galleria
    *ngIf="imageOptions?.length"
    [containerClass]="'cup-images-wrapper'"
    [value]="imageOptions"
    [containerStyle]="{ height: 313 }">
    <ng-template pTemplate="item" let-item>
      <img
        style="cursor: pointer"
        width="300"
        height="200"
        [src]="item?.source"
        (click)="onSelectedImage(item)"
        alt="not-available"
        [pTooltip]="item?.title" />
    </ng-template>
    <ng-template pTemplate="thumbnail" let-item>
      <div class="grid grid-nogutter justify-content-center">
        <img [src]="item.source" height="40" width="60" alt="thumbnail" />
      </div>
    </ng-template>
  </p-galleria>
</ncs-dialog-basic>
<div>
  <p-confirmDialog
    key="inviteCupEvaluatorsDialog"
    header="{{ 'masterdata.dialog.inviteCupEvaluatorsDialog.title' | translate }}"
    [closable]="true"
    [closeOnEscape]="false">
  </p-confirmDialog>
</div>
<p-confirmDialog
  key="confirmAverage"
  header="{{ 'masterdata.dialog.notification' | translate }}"
  [closable]="true"
  [closeOnEscape]="true"
  [rejectVisible]="true"
  icon="fa fa-question-circle"></p-confirmDialog>
