<ng-template ngFor let-child let-i="index" [ngForOf]="menuItems">
  <li [ngClass]="{ 'active-menuitem': isActive(i) }" [class]="child.badgeStyleClass">
    <a
      [href]="child.url || '#'"
      (click)="itemClick($event, child, i)"
      *ngIf="!child.routerLink"
      [attr.target]="child.target">
      <i [title]="child.label | translate" class="material-icons">{{ child?.icon }}</i>
      <span class="menuitem-text">{{ child.label | translate }}</span>
      <i class="fa fa-chevron-down" *ngIf="child.items"></i>
    </a>
    <a
      (click)="itemClick($event, child, i)"
      *ngIf="child.routerLink"
      [routerLink]="child.routerLink"
      routerLinkActive="active-menuitem-routerlink"
      [routerLinkActiveOptions]="{ exact: true }"
      [attr.target]="child.target">
      <i [title]="child.label | translate" class="material-icons">{{ child?.icon }}</i>
      <span class="menuitem-text">{{ child.label | translate }}</span>
      <i class="fa fa-chevron-down" *ngIf="child.items > 1"></i>
    </a>
    <ul
      app-submenu
      [menuItems]="child.items"
      *ngIf="child.items"
      (itemClicked)="childClicked($event)"
      [@children]="isActive(i) ? 'visible' : 'hidden'"
      [visible]="isActive(i)"></ul>
  </li>
</ng-template>
