import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { getAppState } from '../reducers';
import { AppState } from '../reducers/app.reducer';

import { AppTimezoneInfo } from '../../domain/app-timezone-info.model';
import { Country } from '../../domain/country.model';

@Injectable()
export class AppSelector {
  private readonly appFeatureSelector: MemoizedSelector<object, AppState>;

  public constructor() {
    this.appFeatureSelector = getAppState;
  }

  public get getTimezone(): MemoizedSelector<object, AppTimezoneInfo> {
    return createSelector(this.appFeatureSelector, state => state.timezone);
  }

  public get getAllCountries(): MemoizedSelector<object, Country[]> {
    return createSelector(this.appFeatureSelector, state => state.countries);
  }

  /** To correctly display the country column in AgGrid, search the country name by the country id
   * @param {number} countryId Country id
   * @return {string} Country name */
  public findCountryNameById(countryId: number): MemoizedSelector<object, string> {
    return createSelector(this.appFeatureSelector, state => state.countries.find(x => x.id == countryId)?.country);
  }

  /** Retrieve a country
   * @param {number} countryId Country id
   * @return {Country} Country */
  public findCountryById(countryId: number): MemoizedSelector<object, Country> {
    return createSelector(this.appFeatureSelector, state => state.countries.find(x => x.id == countryId));
  }
}
