import { Store } from '@ngrx/store';
import { merge } from 'lodash';
import { extractEvent } from '../../utils/extract-event';
import { MasterdataValidationService } from '../services/masterdata-validation.service';
import * as fromRoot from '../../../core/ngrx/reducers';
import { mergeImmutable } from '../../utils/object-utils';
import { Rule, Validation, ValidationResult } from '../services/master-validation';
import { fetchTranslations } from '../../../core/ngrx/actions';
import { AbstractEntity } from '../../../core/domain/abstract-entity.model';

export abstract class MasterChildComponent<T> {
  public typeFunction: Function;
  public currentChildItem: T;
  public editMode: boolean = false;
  public validationResult: ValidationResult;
  public fullValidation: ValidationResult;

  protected abstract onNewChildItem(): T;

  protected abstract onGetRuleChildValidation(): Rule;

  /** Disabled baseMasterForm toolbar */
  protected abstract onHideParentToolbar(event?: boolean): void;

  /** Save current item */
  protected abstract onAfterItemFieldChange(): void;

  protected constructor(
    protected validationService: MasterdataValidationService,
    protected store: Store<fromRoot.State>,
  ) {}

  onNew(): void {
    this.clearValidationResult();
    this.onEditChildItem(true);
    this.onSetChildItem(this.onNewChildItem());
  }

  private onEditChildItem(event: boolean): void {
    this.onHideParentToolbar(event);
  }

  public onCancel(): void {
    this.onEditChildItem(false);
    this.validationResult = undefined;
  }

  onGetCurrentChildItem(): T {
    return this.currentChildItem;
  }

  protected onGetCurrentChildItemId(): number {
    const itemId = (this.onGetCurrentChildItem() as AbstractEntity)?.id;
    return itemId || 0;
  }

  public onSetChildItem(newChildItem: T): void {
    this.currentChildItem = newChildItem;
  }

  public onItemFieldChange(event: any, fld?: string): void {
    const upd = {};
    upd[fld] = event && !Object.keys(event).length ? event : extractEvent(event);
    const newItem: T = mergeImmutable(<any>this.onGetCurrentChildItem(), upd);
    this.onSetChildItem(newItem);
    let newValidationResult: ValidationResult = this.validationService.clearFieldResults(this.validationResult, fld);
    newValidationResult = merge(
      newValidationResult,
      this.validationService.validateItem(this.onGetCurrentChildItem(), this.onGetRuleChildValidation(), fld),
    );
    setTimeout(() => {
      this.validationResult = newValidationResult;
    });
    this.onAfterItemFieldChange();
  }

  public onItemFieldBlur(fld: string): void {
    let newValidationResult: ValidationResult = this.validationService.clearFieldResults(this.validationResult, fld);
    newValidationResult = merge(
      newValidationResult,
      this.validationService.validateItem(this.onGetCurrentChildItem(), this.onGetRuleChildValidation(), fld),
    );
    setTimeout(() => {
      this.validationResult = newValidationResult;
    });
  }

  protected clearValidationResult(): void {
    this.validationResult = undefined;
  }

  protected validateIsNotEmpty(v: Validation): void {
    if (!v.ctx) {
      v.error('common.validation.empty_field_not_allowed');
    }
  }

  protected validateIsNotEmptyOptions(v: Validation): void {
    if (!v.ctx) {
      v.error('common.validation.empty_field_not_allowed');
    }

    if (v.ctx && v.ctx.length === 0) {
      v.error('common.validation.empty_field_not_allowed');
    }
  }

  public getTranslation(): void {
    this.store.dispatch(
      fetchTranslations({ typeFunction: this.typeFunction, currentId: this.onGetCurrentChildItemId() }),
    );
  }
}
