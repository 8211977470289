import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValueGetterParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from '../../../../../../../core/ngrx/services/authorization.service';
import { TimeService } from '../../../../../../../core/ngrx/services/time-service';
import { QueryService } from '../../../../../../../core/ngrx/services/query.service';
import { hasOwnProperty, isNullOrUndefined, nullsafe } from '../../../../../../utils/object-utils';
import { AbstractCeisService } from '../../../../../../../core/ngrx/services/app.abstract.service';
import { Constants } from '../../../../../../../core/utils/app.constants';
import { Country } from '../../../../../../../core/domain/country.model';
import { Agency } from '../../../../../../../core/domain/agency.model';
import { PaginatedResponse } from '../../../../../../../core/domain/paginated-response.model';
import { LookupParams } from '../../../../../../../shared/models/lookup-params.model';
import { AppStoreService } from '../../../../../../../core/ngrx/store/app.store';
import { BaseMasterGridCol } from '../../../../../models/base-master-grid-col.model';
import { ThirdPartyThirdPartyType } from '../../../../../../../core/domain/third-party-third-party-type.model';
import { NkgGridFactory } from '../../../../../../../shared/grid/services/grid.factory';

@Injectable()
export class ThirdPartyService extends AbstractCeisService {
  private readonly actionAuth: string;
  private readonly actionQC: string;

  constructor(
    http: HttpClient,
    configuration: Constants,
    private readonly authService: AuthorizationService,
    timeService: TimeService,
    private readonly queryService: QueryService,
    private readonly appStoreService: AppStoreService,
    private readonly gf: NkgGridFactory,
    private translateService: TranslateService,
  ) {
    super(http, configuration, timeService);
    this.webStorage = window.localStorage;
    this.actionAuth = configuration.SERVER_WITH_AUTH_API_URL;
    this.actionQC = configuration.SERVER_WITH_QC_API_URL;
  }

  async loadCountryId(countryId: number): Promise<Country> {
    const res = await this.get(`${this.actionAuth}country/${countryId}`, true, () => this.authService.renewToken());
    return this.queryService.fromServer(res);
  }

  async loadAgencyId(agencyId: number): Promise<Agency> {
    const res = await this.get(`${this.actionAuth}agency/${agencyId}`, true, () => this.authService.renewToken());
    return this.queryService.fromServer(res);
  }

  async loadThirdPartyByThirdType(thirdPartyTypeId: number, queryRequest: LookupParams): Promise<PaginatedResponse> {
    const params = {
      thirdPartyType: thirdPartyTypeId,
      thirdParty: queryRequest?.query || '',
      page: queryRequest.page,
      size: 20,
      deleted: false,
    };
    const url = `${this.actionQC}third_party_third_party_type/byThirdPartyTypeId`;

    const res = await this.getWithParams<PaginatedResponse>(url, params, true, () => this.authService.renewToken());
    res.items = nullsafe(res.items).map(item => this.queryService.fromServer(item));
    return res;
  }

  public onGetMasterColDefinitions(): BaseMasterGridCol[] {
    const gridColDefs: BaseMasterGridCol[] = [];
    const cellClassRules = {
      allBorders: function (): boolean {
        return true;
      },
    };

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.id', 'data.id')
        .colId('id')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          minWidth: 35,
          lockPosition: true,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.name', 'data.name')
        .colId('name')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.code', 'data.code')
        .colId('code')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.tax_id', 'data.taxId')
        .colId('taxId')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.tenant', 'data.tenant')
        .colId('tenant')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.nkg_group', (params: ValueGetterParams) => {
          return hasOwnProperty(params.data, 'nkgGroup') && params.data.nkgGroup
            ? this.translateService.instant('common.labels.yes')
            : this.translateService.instant('common.buttons.no');
        })
        .colId('nkgGroup')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.third_party_type', (params: ValueGetterParams) => {
          const thirdPartyThirdPartyTypes: ThirdPartyThirdPartyType[] = params.data.thirdPartyThirdPartyTypes.filter(
            (x: ThirdPartyThirdPartyType) => x.deleted === false,
          );
          return isNullOrUndefined(thirdPartyThirdPartyTypes)
            ? ''
            : thirdPartyThirdPartyTypes.map(x => x.thirdPartyType.thirdPartyType).join(', ');
        })
        .colId('thirdPartyThirdPartyTypes.thirdPartyType.thirdPartyType')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.default_contact', (params: ValueGetterParams) => {
          return isNullOrUndefined(params.data.defaultThirdPartyContact)
            ? ''
            : params.data.defaultThirdPartyContact.firstName;
        })
        .colId('defaultThirdPartyContact.firstName')
        .hidden()
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.default_address', (params: ValueGetterParams) => {
          return isNullOrUndefined(params.data.defaultThirdPartyAddress)
            ? ''
            : params.data.defaultThirdPartyAddress.fullName;
        })
        .colId('defaultThirdPartyAddress.fullName')
        .hidden()
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getTextColDef('common.labels.country', (params: ValueGetterParams) => {
          let countryName: string;
          this.appStoreService.getCountryByID(params.data.countryId).subscribe(countrySrcName => {
            countryName = countrySrcName;
          });
          return countryName;
        })
        .colId('country')
        .hidden()
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
        })
        .build(),
    });

    gridColDefs.push({
      default: true,
      primaryColumn: true,
      colDef: this.gf
        .getColDefBuilder()
        .getDateColDef('common.labels.date', 'data.createdAt')
        .colId('createdAt')
        .addAdditionalParams({
          cellClassRules: cellClassRules,
          cellClass: ['allBorders'],
          lockPosition: true,
        })
        .build(),
    });

    return gridColDefs;
  }

  public onGetAddressTypeColDef(): any[] {
    return [
      {
        field: 'fullName',
        header: 'common.labels.name',
      },
      {
        field: (data: any): string => {
          let countryName: string;
          this.appStoreService.getCountryByID(data.countryId).subscribe(countrySrcName => {
            countryName = countrySrcName;
          });
          return countryName;
        },
        header: 'common.labels.country',
      },
      {
        field: 'city',
        header: 'common.labels.city',
      },
      {
        field: 'state',
        header: 'common.labels.state',
      },
      {
        field: 'zipCode',
        header: 'common.labels.zip_code',
      },
      {
        field: 'streetAddress',
        header: 'common.labels.address_info',
      },
      {
        field: 'phoneNumber',
        header: 'common.labels.phone_number',
      },
      {
        field: 'addressType.name',
        header: 'common.labels.address_type',
      },
    ];
  }

  public onGetCountryById(countryId: number): Country {
    let countrySelection: Country;
    this.appStoreService.getCountryObjByID(countryId).subscribe(country => (countrySelection = country));
    return countrySelection;
  }
}
