import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  createDate,
  createEndOfMonthDate,
  createStartOfMonthDate,
  ncsFormatDateShipmentMonth,
  ncsFormatDateShort,
  ncsFormatDateTimeShort,
  toJavaScriptDateIgnoringTimezone,
  toTimezone,
} from '../../../shared/basic-shared-module/utils/date-utils';
import * as fromRoot from '../reducers';

// allow for mock impls to provide times and dates
@Injectable()
export class TimeService implements OnDestroy {
  private timezone: string = null; // UTC
  private readonly timezoneSubscription: Subscription;

  constructor(private readonly store: Store<fromRoot.State>) {
    this.timezoneSubscription = this.store.select(fromRoot.getTimezone).subscribe(timezone => {
      if (timezone) {
        this.timezone = timezone.timezone;
      }
    });
  }

  public obtainNow(): Date {
    return toTimezone(new Date(), this.timezone);
  }

  public toTimezone(date: Date): Date {
    return toTimezone(date, this.timezone);
  }

  public formatDateShort(date: Date): string {
    return ncsFormatDateShort(date, 'en', this.timezone);
  }

  public formatDateShipmentMonth(date: Date): string {
    return ncsFormatDateShipmentMonth(date, 'en', this.timezone);
  }

  public formatDateTimeShort(date: Date): string {
    return ncsFormatDateTimeShort(date, 'en', this.timezone);
  }

  // all starting with 1, not 0!
  public createDate(year: number, month: number, day: number): Date {
    return createDate(year, month, day, this.timezone);
  }

  // all starting with 1, not 0!
  public createEndOfMonthDate(year: number, month: number): Date {
    return createEndOfMonthDate(year, month, this.timezone);
  }

  // all starting with 1, not 0!
  public createStartOfMonthDate(year: number, month: number): Date {
    return createStartOfMonthDate(year, month, this.timezone);
  }

  // special function that can mimic a Javascript Date with the same Year Month Date but in a totally different time zone (that is the browser one)
  public toJavaScriptDateIgnoringTimezone(date: Date): Date {
    return toJavaScriptDateIgnoringTimezone(date, this.timezone);
  }

  public getTimezone(): string {
    return this.timezone;
  }

  ngOnDestroy(): void {
    this.timezoneSubscription.unsubscribe();
  }
}
