import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { unitOfTime } from 'moment';
import { Constants } from '../../../core/utils/app.constants';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

export function toTimezone(date: Date, timezone: string): Date {
  return moment.tz(date, timezone).toDate();
}

// all starting with 1, not 0!
export function createDate(year: number, month: number, day: number, timezone: string): Date {
  return moment
    .tz(timezone)
    .year(year)
    .month(month - 1)
    .date(day)
    .startOf('day')
    .toDate();
}

// all starting with 1, not 0!
export function createEndOfMonthDate(year: number, month: number, timezone: string): Date {
  return moment
    .tz(timezone)
    .year(year)
    .month(month - 1)
    .endOf('month')
    .startOf('day')
    .toDate();
}

// all starting with 1, not 0!
export function createStartOfMonthDate(year: number, month: number, timezone: string): Date {
  return moment
    .tz(timezone)
    .year(year)
    .month(month - 1)
    .startOf('month')
    .startOf('day')
    .toDate();
}

export function toJavaScriptDateIgnoringTimezone(date: Date, timezone: string): Date {
  const m = moment(date).tz(timezone);
  if (isNullOrUndefined(m)) {
    return m.toDate();
  }
  return new Date(m.year(), m.month(), m.date());
}

export function ncsFormatDateShort(value: Date, lang: string, timezone: string): string {
  if (isNullOrUndefined(value)) return '';
  const ngPipe = new DatePipe(lang);
  return value ? ngPipe.transform(value, Constants.getDateFormatShort(), timezone) : '';
}

export function ncsFormatDateShipmentMonth(value: Date, lang: string, timezone: string): string {
  if (isNullOrUndefined(value)) return '';
  const ngPipe = new DatePipe(lang);
  return value ? ngPipe.transform(value, 'yyyy.MM', timezone) : '';
}

export function ncsFormatDateTimeShort(value: Date, lang: string, timezone: string): string {
  const ngPipe = new DatePipe(lang);
  const displayFormat: string = Constants.getNcsFormatDateTimeShort();
  return value ? ngPipe.transform(value, displayFormat, timezone) : '';
}

export function convertDate(dt: string | number | Date): any {
  if (isNullOrUndefined(dt)) {
    return dt;
  }
  if (typeof dt === 'string') {
    /* Convert ISO 8601 formatted string into Date */
    return moment(dt).toDate();
  }
  if (typeof dt === 'number') {
    /* Convert UTC/epoch timestamp into Date */
    return new Date(dt);
  }
  if (dt instanceof Date) {
    /* Convert Date into ISO 8601 formatted string */
    return dt.toISOString();
  }
  /* Other types are unsupported */
  throw new Error('Expected string, number or Date.');
}

export function add(a: Date, amount: number, unit: unitOfTime.DurationConstructor, timezone: string): Date {
  return moment.tz(a, timezone).add(amount, unit).toDate();
}

export function dateDiffInMonth(a: Date, b: Date, timezone: string): number {
  if (a && b) {
    return moment.tz(a, timezone).diff(b, 'months');
  }
  return 0;
}

/**
 * Substract a month of a date.
 * Does take care of correct month, BUT NOT THE DAY ATM, e.g. 30.February
 * @param {Date} date
 * @param timezone
 * @returns {Date}
 */
export function substractMonth(date: Date, timezone: string): Date {
  if (!date) return undefined;
  return moment.tz(date, timezone).subtract(1, 'months').toDate();
}
