<span class="nkg-block" style="width: 100%">
  <span class="mainCaption" [ngClass]="{ 'nkg-disabled-label': disabled }" *ngIf="label">{{ label | translate }}</span>
  <ng-container *ngIf="editable; then primaryContent; else secondContent"></ng-container>
  <ng-template #primaryContent>
    <ncs-error-tooltip [validationResult]="validationResult" class="tooltip-wrapper">
      <div class="ncs-basic-wrapper" [ngClass]="{ 'ncs-basic-wrapper-disabled': disabled }">
        <input
          pInputText
          #selectionTrigger
          [type]="type"
          [class]="inputClass"
          [style.margin-bottom]=""
          [attr.maxlength]="maxCharacterLength"
          [attr.tabindex]="tabindex"
          [value]="value || ''"
          [placeholder]="placeholder | translate"
          [ngStyle]="inputStyle"
          [disabled]="disabled"
          (focusin)="onFocus()"
          (focusout)="focusin = false"
          (blur)="onBlur($event)"
          (change)="onChanges($event)" />
        <span
          *ngIf="labelTranslation"
          class="text-translate-icon"
          [ngClass]="{ 'translate-disabled': disabledTranslation }"
          (click)="onShowTranslationDialog()">
          <i class="fas fa-globe"></i>
        </span>
        <span *ngIf="showClearButton && value && !disabled" class="text-clear-icon" (click)="onClearValue()">
          <i class="fas fa-times"></i>
        </span>
        <ng-content select="[custom-feature]"></ng-content>
        <ng-content></ng-content>
      </div>
    </ncs-error-tooltip>
  </ng-template>
  <!-- only content -->
  <ng-template #secondContent>
    <span style="display: block; text-overflow: ellipsis; overflow: hidden">{{ value || '' }}</span>
  </ng-template>
</span>
<!-- Translation -->
<ncs-dialog-basic
  *ngIf="labelTranslation && !disabledTranslation"
  [headerText]="'common.labels.translate'"
  [visible]="visibleDialog"
  [secondaryButtonText]="'common.buttons.close'"
  [showOnModal]="dialogOnModal"
  [primaryButtonShow]="false"
  [styleDialog]="{ 'max-width': '600px' }"
  (onHide)="onHideDialog()"
  (onSecondaryClicked)="onHideDialog()">
  <ncs-text-translation
    [editable]="editable"
    [useCurrentMasterItem]="useCurrentItem"
    [entityToTranslate]="currentItemForTranslation"
    [fieldToTranslate]="labelTranslation"
    (onSaveTranslationParent)="onSaveSuccessTranslation.emit($event)"
    (onSaveTranslation)="onSaveTranslation.emit($event)">
  </ncs-text-translation>
</ncs-dialog-basic>
