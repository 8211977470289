<span class="nkg-block" (click)="handleLabelClick($event)">
  <span
    class="mainCaption"
    [ngClass]="{
      'nkg-disabled-label': disabled || (options || []).length == 0
    }"
    >{{ label | translate }}</span
  >
  <ng-container *ngIf="editable; then primaryContent; else secondContent"></ng-container>
  <ng-template #primaryContent>
    <ncs-error-tooltip class="ncs-select tooltip-wrapper" [validationResult]="validationResult">
      <p-dropdown
        [appendTo]="'body'"
        [class]="'ncs-basic-wrapper'"
        [placeholder]="'common.buttons.select' | translate"
        [readonly]="readOnly"
        [style]="inputStyle"
        [options]="options"
        [optionLabel]="fieldReadOnly"
        [dataKey]="dataKey"
        [disabled]="disabled || (options || []).length == 0"
        [required]="required"
        [tabindex]="focusable ? (tabIndex !== undefined ? tabIndex : null) : -1"
        [ngModel]="value"
        [autoDisplayFirst]="false"
        [showClear]="value !== '' && isEmptyAllowed"
        (onChange)="onChange($event)"
        (onBlur)="onBlur()"></p-dropdown>
      <span *ngIf="tooltipInfo && editable" class="tooltip hvr" style="margin-left: 0.5em">
        <span class="fa fa-question-circle"></span>
        <span class="tooltiptext right info">{{ tooltipInfo | translate }}</span>
      </span>
    </ncs-error-tooltip>
  </ng-template>
  <ng-template #secondContent>
    <span *ngIf="value">{{ value[fieldReadOnly] || value || '' }}</span>
  </ng-template>
</span>
