import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { difference } from 'lodash';
import { filter } from 'rxjs/operators';
import * as fromRoot from '../../../core/ngrx/reducers';
import { User } from '../../../core/domain/user.model';
import { Permission } from '../../../core/domain/permission.model';
import { ProtectionDomain } from '../../../core/domain/protection-domain.model';
import { isNullOrUndefined } from '../../../modules/utils/object-utils';

/**
 * Add the template content to the DOM unless the condition is true.
 */
@Directive({ selector: '[restrictTo]' })
export class RestrictAccessDirective implements OnInit {
  @Input() private restrictTo: ProtectionDomain | Permission[] = [];
  private authorized: boolean;

  constructor(
    private readonly store: Store<fromRoot.State>,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.store
      .select(fromRoot.getCurrentUser)
      .pipe(filter(usr => !isNullOrUndefined(usr)))
      .subscribe(user => {
        this.checkPermissions(user);
      });
  }

  private checkPermissions(user: User): void {
    let newAuthorized: boolean = this.onCheckPermissionByERP(user);
    if (newAuthorized) {
      newAuthorized = this.onGetAuthorization(user, this.restrictTo);
    }
    this.renderContentView(newAuthorized);
    this.authorized = newAuthorized;
  }

  private onGetAuthorization(user: User, restrictTo: ProtectionDomain | Permission[] = []): boolean {
    return (
      user &&
      (restrictTo instanceof Array ? user.hasPermissions(restrictTo) : user.canAccessProtectedDomain(restrictTo))
    );
  }

  onCheckPermissionByERP(user: User): boolean {
    const permissionByERP = user.defaultTenant?.hasRestrictionByERP(this.restrictTo as Permission[]);
    // set permission and avoid tenant ERP system restriction
    this.restrictTo = difference(this.restrictTo as Permission[], permissionByERP);
    return user.hasPermissions(permissionByERP);
  }

  private renderContentView(newAuthorized: boolean): void {
    if (newAuthorized !== this.authorized) {
      if (newAuthorized) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if (!newAuthorized) {
        this.viewContainer.clear();
      }
    }
  }
}
