import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { AuthenticationResult } from '@azure/msal-browser';

import {
  appLogin,
  authNotificationEmitter,
  clearAuthNotification,
  fetchCurrentUser,
  getAppMenu,
  getAppMenuSuccess,
  getAppUserByEmailSuccess,
  getAppUserByMail,
  getAppUserByUsername,
  getAppUserByUsernameSuccess,
  loginFailure,
  loginLogoutChecker,
  loginSuccess,
  logout,
  msalLoginFailed,
  msalLoginSuccess,
  msalLogout,
  msalLogoutSuccess,
  resetAuthState,
  saveAppUser,
  setAppErrorMessages,
  setAppNotifications,
  setAppUser,
} from '../actions';
import { setToastNotification, ToastSeverityEnum } from '../utils/notification-utils';
import { UserProfileService } from '../services/user-profile.service';
import { QueryService } from '../services/query.service';
import { AuthorizationService } from '../services/authorization.service';
import { User } from '../../domain/user.model';
import { OptionMenu } from '../../domain/option-menu.model';

@Injectable()
export class AuthEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly authService: AuthorizationService,
    private readonly userProfileService: UserProfileService,
    private readonly queryService: QueryService,
    private readonly translateService: TranslateService,
    private readonly messageService: MessageService,
  ) {}

  public readonly logIn$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(appLogin),
      map(action => action.credentials),
      switchMap(payload =>
        this.authService
          .authorize(payload)
          .then(() => loginSuccess({ username: payload.username }))
          .catch(err => loginFailure({ errorMessage: err })),
      ),
    ),
  );

  public readonly retrieveSilentToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(msalLoginSuccess),
      map(action => action.response),
      switchMap(async (resp: AuthenticationResult) => {
        try {
          const resp1 = await this.authService.requestMsAccessToken(resp.idToken);
          // msalToken doesn't use on any logic
          return appLogin({ credentials: { msalToken: resp1.accessToken } });
        } catch (resp_2) {
          return msalLoginFailed(resp_2);
        }
      }),
    ),
  );

  // remove it if dont use
  public readonly msalLogout$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(msalLogout),
      switchMap(async () => {
        await this.authService.msalLogout();
        return msalLogoutSuccess();
      }),
    ),
  );

  public readonly logInSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSuccess),
      map(action => action.username),
      switchMap(() => [getAppMenu(), setAppNotifications({ notifications: [] })]),
    ),
  );

  public readonly authGetCurrentUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCurrentUser),
      switchMap(() =>
        this.userProfileService
          .loadUser()
          .then((user: User) => setAppUser({ user: user, forceAuthentication: true }))
          .catch(() => setAppErrorMessages({ errorMessages: ['notification.error_occurred'] })),
      ),
    ),
  );

  public readonly authUserSave$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAppUser),
      switchMap(action => this.userProfileService.saveUser(action.user)),
      switchMap(user => [
        setAppUser({ user: user }),
        authNotificationEmitter({ summary: 'notification.success_saved', severity: ToastSeverityEnum.SUCCESS }),
      ]),
      catchError(() => [
        authNotificationEmitter({
          summary: 'notification.error_occurred',
          severity: ToastSeverityEnum.ERROR,
        }),
      ]),
    ),
  );

  public readonly authRequestMenu$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppMenu),
      switchMap(() =>
        this.queryService.obtainMenus().then((menu: OptionMenu[]) => {
          this.router.navigateByUrl('/default-dashboard').then(/* empty */);
          localStorage.setItem('__auth__', JSON.stringify(true));
          return getAppMenuSuccess({ menus: menu });
        }),
      ),
    ),
  );

  public readonly getUserByUsername$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppUserByUsername),
      map(payload => payload.username),
      switchMap(username =>
        this.userProfileService
          .loadUser(username)
          .then((resp: User) => {
            return getAppUserByUsernameSuccess({ user: resp });
          })
          .catch(err => {
            if (err.status === 404) {
              return getAppUserByUsernameSuccess({ user: new User() });
            }
            return setAppErrorMessages({ errorMessages: ['notification.error_occurred'] });
          }),
      ),
    ),
  );

  public readonly getUserByEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppUserByMail),
      map(payload => payload.email),
      switchMap(email =>
        this.userProfileService
          .getUserByEmail(email)
          .then((resp: User) => getAppUserByEmailSuccess({ user: resp }))
          .catch(err => {
            if (err.status === 404) {
              return getAppUserByEmailSuccess({ user: new User() });
            }
            return setAppErrorMessages({ errorMessages: ['notification.error_occurred'] });
          }),
      ),
    ),
  );

  public readonly LogInFailure$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailure),
        tap(() => this.router.navigateByUrl('/login')),
      ),
    { dispatch: false },
  );

  public loginLogOutChecker$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginLogoutChecker),
        tap(() => {
          this.router.navigateByUrl('/login').then(() => {
            this.authService.isLoggedChecker().then(/* empty */);
            return resetAuthState();
          });
        }),
      ),
    { dispatch: false },
  );

  public logOut$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          this.authService.logout().then(async () => {
            await this.router.navigateByUrl('/login');
            return resetAuthState();
          });
        }),
      ),
    { dispatch: false },
  );

  public notificationEmitted$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authNotificationEmitter),
        map(params => {
          this.messageService.add(
            setToastNotification({
              summary: params.summary,
              summaryWithParameters: params?.summaryWithParameters,
              severity: params.severity,
              detailWithParameters: params?.detailWithParameters,
              detail: params?.detail,
              translateService: this.translateService,
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  public clearNotificationEmitted$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clearAuthNotification),
        map(() => this.messageService.clear()),
      ),
    { dispatch: false },
  );
}
